import {
    Dashboard,
    ListAdmins,
    ListProducers,
    ListProcessors,
    ListTrailers,
    ListHaulingCompanies,
    ListDrivers,
    ListScheduleRoutes,
    ManageProcessorTotals,
    ListRouteSessions,
    ListDropoffs,
    ListPickups,
    ListLabReports,
    ListCIPWash,
    ListManifests,
    ListUserGroups,
    GetMilkScheduleDetails,
    ListSharedFiles,
    ViewDriverLocations,
    UploadDownload,
    ListReceivers,
    ManageDeferrals,
    ManageAssignees,
    ManageMilkPrices,
    ManageProducerPayReports,
    ListPickupsAndLabs,
    ListMonthlyReports,
    ManageClosePeriod,
    ListInvoicing,
    ManagePoolingDecisions,
    ManageProducerTouchBases,
    ManageProducerAssignments,
    ManageHaulerAssignments,
    ManageHaulingCharges,
    ManageHaulingContracts,
    ManageHaulerRates,
    ManageVendorPayReports,
    ManagePaymentStatus,
    ManageHaulerPayReports,
    ManageSplitProducers,
    ViewAccountingReports,
    ListSubAdmins,
    ListSubProducers,
    ListSubProcessors,
    ListSubHauling,
    OverrideQualityStatus,
    ProducerHaulingContractsAndCharges,
    ViewReceivingDropoffs,
    ManageDeliveryReports,
    ViewDocumentation,
} from './permissions';
import LABELS from './constants';

const region = 'UNC';

const SubAdminManagement = {
    label: LABELS.Management,
    permissions: [
        ListAdmins,
        ListProducers,
        ListDrivers,
        ListReceivers,
        ListProcessors(region),
        ListHaulingCompanies(region),
        ListTrailers,
        ListSubAdmins,
        // force wrap
    ],
};

const SubAdminPooling = {
    label: LABELS.Pooling,
    permissions: [
        ManagePoolingDecisions,
        ManageProcessorTotals,
        ManageProducerTouchBases,
        // force wrap
    ],
};

const SubAdminGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ListUserGroups,
        ViewDriverLocations,
        ListScheduleRoutes,
        ManageClosePeriod,
        ListPickups(),
        ListDropoffs(),
        ListInvoicing,
        ListCIPWash,
        ListLabReports(),
        UploadDownload,
        ListRouteSessions(region),
        ManageDeliveryReports,
        ListManifests,
        GetMilkScheduleDetails(),
        OverrideQualityStatus,
        ListSharedFiles,
        ViewDocumentation,
        ViewReceivingDropoffs,
        // force wrap
    ],
};

const SubAdminAccounting = {
    label: LABELS.Accounting,
    permissions: [
        ViewAccountingReports,
        ManageProducerAssignments,
        ManageHaulerAssignments,
        ManageAssignees,
        ManageMilkPrices,
        ManageDeferrals,
        ManageSplitProducers,
        ManageHaulingCharges(),
        ManageHaulingContracts(),
        ManageHaulerRates,
        ManageProducerPayReports,
        ManageHaulerPayReports,
        ManageVendorPayReports,
        ManagePaymentStatus,
        // force wrap
    ],
};

const SubProducerManagement = {
    label: LABELS.Management,
    permissions: [ListSubProducers],
};

const SubProducerGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ListPickups(),
        ListPickupsAndLabs,
        ListLabReports(),
        ListSharedFiles,
        // force wrap
    ],
};

const SubProducerAccounting = {
    label: LABELS.Accounting,
    permissions: [ListMonthlyReports(region)],
};

const SubProcessorManagement = {
    label: LABELS.Management,
    permissions: [ListSubProcessors('View', ''), ListReceivers],
};

const SubProcessorGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ListDropoffs(),
        ListInvoicing,
        ListRouteSessions(),
        ListManifests,
        GetMilkScheduleDetails(),
        ListSharedFiles,
        ViewReceivingDropoffs,
        // force wrap
    ],
};

const SubHaulingManagement = {
    label: LABELS.Management,
    permissions: [ListDrivers, ListTrailers, ListSubHauling('View', '')],
};

const SubHaulingGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ViewDriverLocations,
        ListPickups(),
        ListDropoffs(),
        ListCIPWash,
        GetMilkScheduleDetails(),
        ListSharedFiles,
        ViewReceivingDropoffs,
        // force wrap
    ],
};

const SubHaulingAccounting = {
    label: LABELS.Accounting,
    permissions: [ProducerHaulingContractsAndCharges, ListMonthlyReports(region)],
};

const SubAdmin = [SubAdminManagement, SubAdminGeneral, SubAdminAccounting, SubAdminPooling];
const SubProducer = [SubProducerManagement, SubProducerGeneral, SubProducerAccounting];
const SubProcessor = [SubProcessorManagement, SubProcessorGeneral];
const SubHauling = [SubHaulingManagement, SubHaulingGeneral, SubHaulingAccounting];

const REQUIRED_PERMISSIONS = {
    Admin: [
        ListUserGroups,
        ViewReceivingDropoffs,
        ListInvoicing,
        OverrideQualityStatus,
        ListManifests,
        GetMilkScheduleDetails(),
        ListTrailers,
        // force-wrap
    ],
};

const UNC = {
    Admin: { permissions: SubAdmin, required: REQUIRED_PERMISSIONS.Admin },
    'Sub-Admin': { permissions: SubAdmin },
    'Sub-Producer': { permissions: SubProducer },
    Accountant: { permissions: SubProducer },
    'Feed-Provider': { permissions: SubProducer },
    Nutritionist: { permissions: SubProducer },
    'Co-Owner': { permissions: SubProducer },
    'Sub-Processor': { permissions: SubProcessor },
    'Sub-Hauling': { permissions: SubHauling },
};

export default UNC;
