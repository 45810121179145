import { DRIVER_LOCATION_REGIONS } from '../../../app/constants/bulkRegion';
import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';

const prod = new Environment({
    name: stages.PRODUCTION,
    regions: DRIVER_LOCATION_REGIONS,
});

const demo = new Environment({
    ...prod,
    name: stages.DEMO,
});

const staging = new Environment({
    name: stages.STAGING,
    regions: DRIVER_LOCATION_REGIONS,
});

const local = new Environment({
    ...staging,
    name: stages.LOCAL,
});

const dev = new Environment({
    ...staging,
    name: stages.DEV,
});

const use_driver_locations = new MockSplit({
    name: 'use_driver_locations',
    environments: [local, dev, staging, demo, prod],
});

export default use_driver_locations;
