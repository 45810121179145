import authRoles from 'app/auth/authRoles';
import { FuseLoadable } from '@fuse';

export const LocationsConfig = {
    auth: authRoles.hauling,
    routes: [
        {
            exact: true,
            path: '/driver-locations',
            component: FuseLoadable({ loader: () => import('./DriverLocationsSplitRouter') }),
        },
    ],
};

export default [LocationsConfig];
