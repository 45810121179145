import { setDefaultSettings, setInitialSettings } from 'app/store/actions/fuse';
import store from 'app/store';
import * as FullStory from '@fullstory/browser';
import * as Actions from 'app/store/actions';
import JWTService from 'app/services/jwtService';
import _ from '@lodash';
import Split from '../../../../Split';
import { updateUserPermissions } from '../../permissions';
import { datadogRum } from '@datadog/browser-rum';
import { getUser } from '../../../../utils';

export const SET_USER_DATA = '[USER] SET DATA';
export const REMOVE_USER_DATA = '[USER] REMOVE DATA';
export const USER_LOGGED_OUT = '[USER] LOGGED OUT';

function setUserSplitAttributes(user) {
    const attributes = user?.data;
    if (attributes) Split.setAttributes(attributes);
}

/**
 * Update User Data
 */
function updateUserData(user) {
    if (user.role === 'guest') {
        return;
    }

    setUserSplitAttributes(user);
    JWTService.updateUserData(user).then(() => {
        store.dispatch(Actions.showMessage({ message: 'User data updated successfully.' }));
    });
}

export function setUserData(user) {
    return (dispatch) => {
        /*
        Set User Settings
         */
        dispatch(setDefaultSettings(user));

        /*
        Set User Data
         */
        dispatch({ type: SET_USER_DATA, payload: user });
        updateUserPermissions(user);
        setUserSplitAttributes(user);
    };
}

/**
 * Update User Settings
 */
export function updateUserSettings(settings) {
    return (dispatch, getState) => {
        const oldUser = getUser();
        const user = _.merge({}, oldUser, { data: { settings } });

        updateUserData(user);

        return dispatch(setUserData(user));
    };
}

/**
 * Update User Shortcuts
 */
export function updateUserShortcuts(shortcuts) {
    return (dispatch, getState) => {
        const user = getUser();
        const newUser = { ...user, data: { ...user.data, shortcuts } };
        updateUserData(newUser);

        return dispatch(setUserData(newUser));
    };
}

/**
 * Remove User Data
 */
export function removeUserData() {
    return { type: REMOVE_USER_DATA };
}

/**
 * Logout
 */
export function logoutUser() {
    return (dispatch, getState) => {
        const user = getUser();
        sessionStorage.removeItem('state');

        if (user.role === 'guest') {
            return null;
        }

        window.history.replaceState(null, 'Milk Moovement', '/#/login');

        JWTService.logout().then(() => {
            FullStory.anonymize();
            datadogRum.stopSession();
            datadogRum.clearUser();
            dispatch(setInitialSettings());
            dispatch({ type: USER_LOGGED_OUT });
            window.location.reload();
        });
    };
}

/**
 * Lock
 */
export function lockUser() {
    JWTService.lock();
}

/**
 * Check if user is logged in
 */
export function isUserGuest() {
    return (dispatch, getState) => {
        const user = getUser();
        if (user.role === 'guest') {
            return false;
        }

        return true;
    };
}

/**
 * Update User Data
 */
