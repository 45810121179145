import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Alert } from '@mui/material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';
import { PayReportDownloadStatus, PaymentType, PayReportPageType } from 'app/constants/report';
import { uniqueId } from 'lodash';
import PayReportPublishDate from '../../custom-components/pay-reports/PayReportPublishDate';

const PayReportApproveAllDialog = ({ dialogOpen, handleClose, selectedDate, handleDownloadAll, reportType, useAsyncTask, payReports, payReportPageType }) => {
    const [ungeneratedPayReports, setUngeneratedPayReports] = useState([]);
    const [selectedPublishDate, setSelectedPublishDate] = useState(moment());
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [usePublishDate, setUsePublishDate] = useState(false);

    useEffect(() => {
        if (dialogOpen) {
            if (payReportPageType === PayReportPageType.producer) {
                setShowDatePicker(true);
            }
            const ungeneratedPayReportList = payReports[reportType]?.reduce((acc, report) => {
                if (report[`generated_${reportType}_report`] === false) {
                    const payeeNumber = report?.producer_number ?? report?.hauler?.hauler_number ?? report?.vendor?.vendor_number;
                    const payeeName = report?.name ?? report?.hauler?.name ?? report?.vendor?.vendor_name;
                    acc.push(`${payeeNumber} ${payeeName}`);
                }
                return acc;
            }, []);
            setUngeneratedPayReports(ungeneratedPayReportList);
        } else {
            setUngeneratedPayReports([]);
            setUsePublishDate(false);
            setShowDatePicker(false);
            setSelectedPublishDate(moment());
        }
    }, [dialogOpen]);

    return (
        <Dialog open={dialogOpen} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle id="draggable-dialog-title">Approve Payroll Statements</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Approve all {moment(selectedDate).format('MMMM YYYY')} {reportType} payroll statements?
                    {ungeneratedPayReports.length > 0 && (
                        <Alert severity="warning">
                            <b>Warning:</b> The following new {reportType} pay statements will be generated and included in the approval:
                            <ul>
                                {ungeneratedPayReports.map((report) => (
                                    <li key={uniqueId()}>{report}</li>
                                ))}
                            </ul>
                        </Alert>
                    )}
                    {showDatePicker && (
                        <PayReportPublishDate
                            selectedPublishDate={selectedPublishDate}
                            setSelectedPublishDate={setSelectedPublishDate}
                            usePublishDate={usePublishDate}
                            setUsePublishDate={setUsePublishDate}
                            // wrap
                        />
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleClose();
                        const status = PayReportDownloadStatus.approve;
                        // Only send the publish date if the checkbox is checked.
                        const payload = reportType !== PaymentType.deferral && useAsyncTask ? { payReportDownloadStatus: status, ...(usePublishDate && { publishDate: moment(selectedPublishDate).toDate() }) } : status;
                        handleDownloadAll(payload);
                    }}
                    variant="contained"
                    color="primary"
                >
                    Approve
                </Button>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PayReportApproveAllDialog;
