import { dataTableCSVExport } from '../../../../utils';
import cdiConfig from './CDI';
import prairieConfig from './PRAIRIE';

const getConfig = (region) => {
    const config = {
        datatable: {
            getTitle: () => 'Dropoff Summary Statistics',
            getColumns: () => {
                switch (region) {
                    case 'CDI':
                        return cdiConfig.getColumns();
                    case 'PRAIRIE':
                        return prairieConfig.getColumns();
                    default:
                        return [];
                }
            },
            getData: (data) => {
                switch (region) {
                    case 'CDI':
                        return cdiConfig.getData(data);
                    case 'PRAIRIE':
                        return prairieConfig.getData(data);
                    default:
                        return {};
                }
            },
            getOptions: () => ({
                pageSize: 10,
                exportMenu: [
                    {
                        label: 'Export CSV',
                        exportFunc: dataTableCSVExport('Dropoff Statistics'),
                    },
                ],
            }),
        },
    };
    return config;
};

export default getConfig;
