import moment from 'moment-timezone';
import axios from 'axios';
import _ from 'lodash';
import { batchQuery, getRouteSessionsQuery, getRoutesQuery, getHaulingCompaniesQuery, getDriversQuery, bulkQuery } from './queries';
import { arrayToMap, renameKey } from '../../utils';

// TODO:
// Refactor: start with hauling company -> then get drivers for that hauling company -> then get route sessions
// based on those drivers

const getCurrentDriverLocations = (id) =>
    new Promise((resolve, reject) => {
        const start = moment().startOf('day');
        const end = moment().endOf('day');
        const routeSessionQuery = getRouteSessionsQuery({
            start,
            end,
            status: 'active',
            fields: ['id', 'status', 'BOL', 'route_id', 'created_by', 'created_at', 'deleted_at', 'route_points'],
            filterDeleted: true,
        });

        const routeSessionRequest = axios.get(routeSessionQuery);
        routeSessionRequest
            .then((routeSessionResponse) => {
                const routeSessions = routeSessionResponse.data;
                const routeIds = _.uniq(_.map(routeSessions, 'route_id'));
                const driverIds = _.uniq(_.map(routeSessions, 'created_by'));

                const routesQuery = bulkQuery(getRoutesQuery, { ids: routeIds }, 'ids');
                const routesRequest = axios.post(routesQuery.url, routesQuery.body);

                const driversQuery = bulkQuery(getDriversQuery, { ids: driverIds }, 'ids');
                const driversRequest = axios.post(driversQuery.url, driversQuery.body);

                const haulingCompaniesQuery = bulkQuery(getHaulingCompaniesQuery, { fields: ['id', 'name'] });
                const haulingCompaniesRequest = axios.post(haulingCompaniesQuery.url, haulingCompaniesQuery.body);

                const requests = [routesRequest, driversRequest, haulingCompaniesRequest];

                axios.all(requests).then(
                    axios.spread((...responses) => {
                        const routes = responses[0].data;
                        renameKey(routes, '_id', 'id');

                        const drivers = id ? responses[1].data.filter((driver) => driver.hauling_id === id) : responses[1].data;

                        renameKey(drivers, '_id', 'id');

                        const driversMap = arrayToMap(drivers, 'id');

                        const haulingCompanies = responses[2].data;
                        renameKey(haulingCompanies, '_id', 'id');
                        const haulingCompanyMap = arrayToMap(haulingCompanies, 'id');

                        const sessionsWithRoutePoints = _.filter(routeSessions, (session) => !_.isNull(session.route_points) && !_.isUndefined(session.route_points) && !_.isEmpty(session.route_points));

                        const locations = _.map(sessionsWithRoutePoints, (rs) => ({
                            created_at: !!rs.route_points && !!rs.route_points[0] ? moment(rs.route_points.slice(-1)[0].time).format() : rs.created_at,

                            driver_name: _.find(drivers, { id: rs.created_by }) ? _.find(drivers, { id: rs.created_by }).name : null,

                            hauling_company_name: haulingCompanyMap?.[driversMap?.[rs.created_by]?.hauling_id]?.name || '',
                            id: -1, // deprecated?
                            latitude: rs.route_points.slice(-1)[0].lat,
                            longitude: rs.route_points.slice(-1)[0].lng,
                            route_name: _.find(routes, { id: rs.route_id }) ? _.find(routes, { id: rs.route_id }).name : '',
                            session_id: rs.id,
                        }));
                        resolve({ locations: locations.filter((location) => location.driver_name !== null), success: true });
                    })
                );
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getCurrentDriverLocations;
