import store from 'app/store';
import { FuseLoadable } from '@fuse';
import Split from '../../../../../Split';
import { getRegion } from '../../../../../utils';

const Index = {
    auth: ['admin', 'sub-admin', 'producer', 'sub-producer', 'lab_provider', 'processor', 'sub-processor'],
    routes: [
        {
            path: '/lab-reports/:date?',
            component: FuseLoadable({
                loader: () => {
                    switch (store.getState().persisted.auth.user.role) {
                        case 'admin':
                        case 'sub-admin':
                        case 'lab_provider':
                            return import('./pickup-sample/admin/AdminLabsApp');
                        case 'producer':
                        case 'sub-producer':
                            return import('./pickup-sample/producer/ProducerLabsApp');
                        case 'processor':
                        case 'sub-processor':
                            return import('./pickup-sample/processor/ProcessorLabsApp');
                        default:
                            break;
                    }
                },
            }),
        },
    ],
};

const DropoffSamples = {
    auth: ['admin', 'sub-admin', 'lab_provider', 'processor', 'sub-processor'],
    routes: [
        {
            path: '/dropoff-samples',
            component: FuseLoadable({
                loader: () => {
                    switch (store.getState().persisted.auth.user.role) {
                        case 'admin':
                        case 'sub-admin':
                        case 'lab_provider':
                            return import('./dropoff-sample/AdminDropoffSampleApp');
                        case 'processor':
                        case 'sub-processor':
                            return import('./dropoff-sample/ProcessorDropoffSampleApp');
                        default:
                            break;
                    }
                },
            }),
        },
    ],
};

const BarcodeInputApp = {
    auth: ['admin', 'sub-admin', 'lab_provider'],
    routes: [
        {
            path: '/barcode-input/:id?',
            component: FuseLoadable({
                loader: () => {
                    switch (store.getState().persisted.auth.user.data.region) {
                        case 'CDI':
                            return import('./BarcodeInputApp/barcode-input-app-old/BarcodeInputAppOld');
                        default:
                            return import('./BarcodeInputApp/BarcodeInputApp');
                    }
                },
            }),
        },
    ],
};

const SampleBarcodeBatchesApp = {
    auth: ['admin', 'sub-admin'],
    routes: [
        {
            path: '/sample-barcode-batches',
            component: FuseLoadable({
                loader: () => import('./SampleBarcodeBatchApp/SampleBarcodeBatchListApp'),
            }),
        },
    ],
};

const SampleBarcodeBatchApp = {
    auth: ['admin', 'sub-admin'],
    routes: [
        {
            path: '/sample-barcode-batch/:id',
            component: FuseLoadable({
                loader: () => import('./SampleBarcodeBatchApp/SampleBarcodeBatchApp'),
            }),
        },
    ],
};

const PrintBarcodesApp = {
    auth: ['admin', 'sub-admin'],
    routes: [
        {
            path: '/print-queue',
            component: FuseLoadable({
                loader: () => import('./print-queue/PrintBarcodesApp'),
            }),
        },
    ],
};

const MiscSamplesApp = {
    auth: ['admin', 'sub-admin', 'producer', 'sub-producer'],
    routes: [
        {
            path: '/misc-samples',
            component: FuseLoadable({
                loader: () => {
                    switch (store.getState().persisted.auth.user.role) {
                        case 'admin':
                        case 'sub-admin':
                            return import('./misc-sample/AdminMiscSampleApp');
                        case 'producer':
                        case 'sub-producer':
                            return import('./misc-sample/ProducerMiscSampleApp');
                        default:
                            break;
                    }
                },
            }),
        },
    ],
};

const AwaitingDropoffRapidTest = {
    auth: ['admin', 'sub-admin', 'processor', 'sub-processor'],
    routes: [
        {
            path: '/awaiting-dropoff-rapid-test',
            component: FuseLoadable({
                loader: () => {
                    if (store.getState().persisted.auth.user.data.permissions?.includes('ManageRapidTest') || getRegion(window.location.hostname) === 'US-DEMO') return import('./dropoff-rapid-test/AwaitingDropoffRapidTestApp');
                    return import('../../../pages/errors/404/Error404Page');
                },
            }),
        },
    ],
};

const QualityStatusAndOverridesApp = {
    auth: ['admin', 'sub-admin'],
    routes: [
        {
            path: '/quality-status-and-overrides',
            component: FuseLoadable({
                loader: () => import('./quality-status-and-overrides/QualityStatusAndOverridesApp'),
            }),
        },
    ],
};

const BarnScoresApp = {
    auth: ['admin', 'sub-admin'],
    routes: [
        {
            path: '/barn-scores',
            component: FuseLoadable({
                loader: () => import('./barn-score/BarnScoreApp'),
            }),
        },
    ],
};

export default [Index, BarcodeInputApp, DropoffSamples, SampleBarcodeBatchesApp, SampleBarcodeBatchApp, PrintBarcodesApp, MiscSamplesApp, AwaitingDropoffRapidTest, QualityStatusAndOverridesApp, BarnScoresApp];
