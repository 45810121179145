import axios from 'axios';
import Split from '../../Split';
import { editLabReportQuery } from './queries';
import { isLabAlreadyExistsMsg, isPickupLabAlreadyExistsMsg, getUserRegion } from '../../utils';
import { buildExpressQuery } from './queries/builders';

/**
 * Handles updating various lab components based on the 'is_component_outlier' field.
 * If 'is_component_outlier' is true:
 *  - Sets the component values to 0 for the MMPA region.
 *  - Grabs the four-day average values for 'lactose', 'other_solids', 'fat', 'protein', and 'solids_not_fat'
 *    for the CDI region.
 */
const updateLabFieldsBasedOnComponentOutlier = (query, model, region) => {
    const updatedModel = { ...model };

    if (['MMPA'].includes(region) && model.is_component_outlier) {
        updatedModel.fat = 0;
        updatedModel.protein = 0;
        updatedModel.lactose = 0;
        updatedModel.other_solids = 0;
        updatedModel.solids_not_fat = 0;
        updatedModel.milk_urea_nitrogen = 0;
        updatedModel.total_solids = 0;
        updatedModel.somatic_cell_count = 0;
    } else if (['CDI', 'CACIQUE'].includes(region)) {
        if (model.is_component_outlier) {
            updatedModel.lactose = model?.four_day_average?.lactose;
            updatedModel.other_solids = parseFloat((model?.four_day_average?.solids_not_fat - model?.four_day_average?.protein).toFixed(2));
            updatedModel.fat = model?.four_day_average?.fat;
            updatedModel.protein = model?.four_day_average?.protein;
            updatedModel.solids_not_fat = model?.four_day_average?.solids_not_fat;
            updatedModel.manual_adjustment = model.is_component_outlier; // or true
        } else if (model.is_outlier) {
            updatedModel.manual_adjustment = model.is_outlier; // or true
            updatedModel.test_frequency = 'daily';
        }
    }

    return axios.patch(query.url, { ...query.body, ...updatedModel });
};

// Revised editLabReport function utilizing the updateLabFieldsBasedOnComponentOutlier function
const editLabReport = (model, key) =>
    new Promise((resolve, reject) => {
        const useExpressEndpoint = Split.getBooleanTreatment('use_express_lab_endpoint');
        const query = editLabReportQuery({ key, model, ...(useExpressEndpoint && { queryBuilder: buildExpressQuery }) });
        // Get user region to pass into the updateLabFieldsBasedOnComponentOutlier function
        const region = getUserRegion();

        let requestData; // Variable to store the response from the first PATCH request

        const request = updateLabFieldsBasedOnComponentOutlier(query, model, region);

        request
            .then((response) => {
                requestData = response.data; // Store the response data
            })
            .then(() => {
                resolve({ data: requestData }); // Resolve with the response data from the first request
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.error(error);
                if (error.response && error.response.status === 405) {
                    const { data } = error.response;
                    if (isPickupLabAlreadyExistsMsg(data.message) || isLabAlreadyExistsMsg(data.message)) {
                        reject(new Error(data.message));
                    }
                } else {
                    reject(new Error('Unable to edit lab report'));
                }
            });
    });

export default editLabReport;
