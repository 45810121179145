import authRoles from 'app/auth/authRoles';
import { FuseLoadable } from '@fuse';

export const EquityStatementsConfig = {
    auth: authRoles.producer,
    routes: [
        {
            exact: true,
            path: '/equity/statements',
            component: FuseLoadable({ loader: () => import('./statements') }),
        },
        {
            exact: true,
            path: '/equity/statements/:slug',
            component: FuseLoadable({ loader: () => import('./statements') }),
        },
        {
            exact: true,
            path: '/equity/statements/master-values/:name',
            component: FuseLoadable({ loader: () => import('./statements/StatementMasterValuesDetails') }),
        },
        {
            exact: true,
            path: '/equity/statements/generation/:slug/:year/:id/:step',
            component: FuseLoadable({ loader: () => import('./statements/statementGeneration/StatementGenerationWizard') }),
        },
    ],
};

export const EquityMembership = {
    auth: authRoles.admin,
    routes: [
        {
            exact: true,
            path: '/equity/membership',
            component: FuseLoadable({ loader: () => import('./membership') }),
        },
        {
            exact: true,
            path: '/equity/membership/create',
            component: FuseLoadable({ loader: () => import('./membership/MembershipEntityCreateAndEdit') }),
        },
        {
            exact: true,
            path: '/equity/membership/purchase/:equityType',
            component: FuseLoadable({ loader: () => import('./membership/MembershipEquityPurchase') }),
        },
        {
            exact: true,
            path: '/equity/membership/:id',
            component: FuseLoadable({ loader: () => import('./membership/MembershipDetail') }),
        },
        {
            exact: true,
            path: '/equity/membership/:id/history/:equityType',
            component: FuseLoadable({ loader: () => import('./membership/MembershipEquityHistory') }),
        },
        {
            exact: true,
            path: '/equity/edit-membership/:id',
            component: FuseLoadable({ loader: () => import('./membership/MembershipEntityCreateAndEdit') }),
        },
        {
            exact: true,
            path: '/equity/membership/:id/reallocate',
            component: FuseLoadable({ loader: () => import('./membership/MembershipReallocateEquity') }),
        },
        {
            exact: true,
            path: '/equity/membership/:id/transfer/:equityType',
            component: FuseLoadable({ loader: () => import('./membership/MembershipEquityTransfer') }),
        },
        {
            exact: true,
            path: '/equity/membership/:id/sale/:equityType',
            component: FuseLoadable({ loader: () => import('./membership/MembershipEquityTransfer') }),
        },
        {
            exact: true,
            path: '/equity/membership/:id/early-redemption',
            component: FuseLoadable({ loader: () => import('./membership/MembershipEarlyRedemption') }),
        },
        {
            exact: true,
            path: '/equity/membership/:id/estates',
            component: FuseLoadable({ loader: () => import('./membership/MembershipEstates') }),
        },
        {
            exact: true,
            path: '/equity/membership/:id/revolvement',
            component: FuseLoadable({ loader: () => import('./membership/MembershipEquityRevolvement') }),
        },
    ],
};

/**
 * Returns a slugified string from any string
 * @param {string} str
 * @returns {string}
 */
export function slugify(str) {
    return String(str)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
}

export default [EquityStatementsConfig, EquityMembership];
