import moment from 'moment-timezone';

export const InvoicingActions = {
    SET_PROCESSORS: '[INVOICING] SET_PROCESSORS',
    SET_PROCESSORS_STATUS: '[INVOICING] SET_PROCESSORS_STATUS',
    SET_PROCESSORS_SUMMARY_STATUS: '[INVOICING] SET_PROCESSOR_SUMMARY_STATUS',
    SET_ASSIGNMENT_DATE: '[INVOICING] SET_ASSIGNMENT_DATE',
};

const initialState = {
    assignmentDate: moment(),
    processors: null,
    status: { SETTLEMENT: {}, ADVANCE: {} },
    summaryStatus: { SETTLEMENT: {}, ADVANCE: {} }, //
};

const invoicingReducer = function (state = initialState, action) {
    const FIFTEEN_MINUTES_MS = 1000 * 60 * 15;
    const ONE_HOUR_MS = 1000 * 60 * 60;
    switch (action.type) {
        case InvoicingActions.SET_PROCESSORS: {
            const currentTimestamp = new Date().getTime() + ONE_HOUR_MS;
            return { ...state, processors: { expiry: currentTimestamp, data: action.payload } };
        }
        case InvoicingActions.SET_PROCESSORS_STATUS: {
            const currentTimestamp = new Date().getTime() + FIFTEEN_MINUTES_MS;

            return {
                ...state,
                status: {
                    ...state.status,
                    [action.payload.paymentType]: {
                        ...state.status[action.payload.paymentType],
                        [action.payload.month]: {
                            expiry: currentTimestamp,
                            data: action.payload.data,
                        },
                    },
                },
            };
        }
        case InvoicingActions.SET_PROCESSORS_SUMMARY_STATUS: {
            const currentTimestamp = new Date().getTime() + FIFTEEN_MINUTES_MS;

            return {
                ...state,
                summaryStatus: {
                    ...state.summaryStatus,
                    [action.payload.paymentType]: {
                        ...state.summaryStatus[action.payload.paymentType],
                        [action.payload.month]: {
                            expiry: currentTimestamp,
                            data: action.payload.data,
                        },
                    },
                },
            };
        }
        case InvoicingActions.SET_ASSIGNMENT_DATE: {
            return { ...state, assignmentDate: action.payload };
        }
        default:
            return state;
    }
};

export default invoicingReducer;
