import store from 'app/store';
import { authRoles } from 'app/auth';
import { FuseLoadable } from '@fuse';

export const MilkPrice = {
    auth: authRoles.admin,
    routes: [
        {
            path: '/milk-prices',
            component: FuseLoadable({
                loader: () => import('./CreateMilkPriceApp'),
            }),
        },
        {
            path: '/milk-prices/weekly-advance',
            component: FuseLoadable({
                loader: () => import('./CreateMilkPriceApp'),
            }),
        },
    ],
};

export const HaulingRates = { auth: authRoles.admin, routes: [{ path: '/hauling-rates', component: FuseLoadable({ loader: () => import('./cdi/CreateCDIHaulingRateApp') }) }] };

export default [MilkPrice, HaulingRates];
