import store from 'app/store';
import { getId } from '../../../../utils';
import * as repository from '../../../repository';

export const GET_ADMIN_DASHBOARD_DETAILS = '[ADMIN] GET_ADMIN_DASHBOARD_DETAILS';
export const GET_ADMIN_ESTIMATED_FLUID_SALES_DETAILS = '[ADMIN] GET_ADMIN_ESTIMATED_FLUID_SALES_DETAILS';
export const GET_ADMIN_IN_TRANSIT_DETAILS = '[ADMIN] GET_ADMIN_IN_TRANSIT_DETAILS';
export const GET_ADMIN_ON_FARM_ESTIMATE_DETAILS = '[ADMIN] GET_ADMIN_ON_FARM_ESTIMATE_DETAILS';
export const GET_ADMIN_LAB_REPORTS = '[ADMIN] GET_ADMIN_LAB_REPORTS';
export const GET_ADMIN_MONTHLY_REPORTS = '[ADMIN] GET_ADMIN_MONTHLY_REPORTS';
export const GET_BACTO_CHARTS_DETAILS = '[ADMIN} GET_BACTO_CHARTS_DETAILS';

export const GET_PRODUCER_DASHBOARD_DETAILS = '[PRODUCER] GET_PRODUCER_DASHBOARD_DETAILS';
export const GET_PRODUCER_DASHBOARD_LABREPORT_DETAILS = '[PRODUCER] GET_PRODUCER_DASHBOARD_LABREPORT_DETAILS';
export const GET_PRODUCER_DASHBOARD_LABAVERAGES_DETAILS = '[PRODUCER] GET_PRODUCER_DASHBOARD_LABAVERAGES_DETAILS';
export const GET_PRODUCER_LAB_REPORTS = '[PRODUCER] GET_PRODUCER_LAB_REPORTS';
export const GET_PRODUCER_MONTHLY_REPORTS = '[PRODUCER] GET_PRODUCER_MONTHLY_REPORTS';

export const GET_CLOSE_DAY_DETAILS = 'GET_CLOSE_DAY_DETAILS';
export const GET_CLOSE_MONTH_DETAILS = 'GET_CLOSE_MONTH_DETAILS';
export const GET_HAULING_ROUTE_SESSIONS_DETAILS = 'GET_HAULING_ROUTE_SESSIONS_DETAILS';
export const GET_HAULING_DRIVER_LIST_DETAILS = 'GET_HAULING_DRIVER_LIST_DETAILS';
export const GET_HAULING_DROPOFF_DETAILS = 'GET_HAULING_DROPOFF_DETAILS';
export const GET_HAULING_PICKUP_DETAILS = 'GET_HAULING_PICKUP_DETAILS';

export const GET_PROCESSOR_DASHBOARD_DETAILS = '[PROCESSOR] GET_PROCESSOR_DASHBOARD_DETAILS';
export const GET_PROCESSOR_RECEIVER_LIST_DETAILS = 'GET_PROCESSOR_RECEIVER_LIST_DETAILS';
export const GET_PROCESSOR_ROUTE_SESSIONS_DETAILS = 'GET_PROCESSOR_ROUTE_SESSIONS_DETAILS';
export const GET_PROCESSOR_DROPOFF_DETAILS = 'GET_PROCESSOR_DROPOFF_DETAILS';
export const GET_PROCESSOR_LAB_REPORTS = 'GET_PROCESSOR_LAB_REPORTS';

export const GET_PICKUP_DETAILS = 'GET_PICKUP_DETAILS';
export const GET_PICKUPS_LABS_DETAILS = 'GET_PICKUPS_LABS_DETAILS';
export const GET_DROPOFF_DETAILS = 'GET_DROPOFF_DETAILS';
export const GET_PICKUPS_PROCESSOR_DETAILS = 'GET_PENALTY_STATUS_DETAILS';
export const GET_PENALTY_STATUS_DETAILS = 'GET_PICKUPS_PROCESSOR_DETAILS';
export const GET_BASE_TRANSFERS_DETAILS = 'GET__DETAILS';
export const GET_WASH_DETAILS = 'GET_WASH_DETAILS';
export const GET_WASH_FACILITY_DETAILS = 'GET_WASH_FACILITY_DETAILS';
export const GET_ROUTE_SESSIONS_DETAILS = 'GET_ROUTE_SESSIONS_DETAILS';
export const GET_POOLING_SESSION_DETAILS = 'GET_POOLING_SESSION_DETAILS';

export const GET_SESSION_DETAILS = 'GET_SESSION_DETAILS';
export const GET_ROUTE_SESSION_DROPOFFS = 'GET_ROUTE_SESSION_DROPOFFS';
export const GET_ROUTE_SESSION_PICKUPS = 'GET_ROUTE_SESSION_PICKUPS';

export const GET_BACTO_ALERT_DETAILS = 'GET_BACTO_ALERT_DETAILS';

export const UPDATE_ROUTE_SESSION_STATUS = 'UPDATE_ROUTE_SESSION_STATUS';

export const GET_ROUTE_SESSIONS = 'GET_ROUTE_SESSIONS';
export const GET_DROPOFF_TOTALS = 'GET_DROPOFF_TOTALS';
export const GET_MONTH_BACTO_DETAILS = 'GET_MONTH_BACTO_DETAILS';
export const GET_ROUTE_STATS = 'GET_ROUTE_STATS';
export const GET_DRIVER_STATS = 'GET_DRIVER_STATS';
export const GET_DRIVER_ROUTE_SESSIONS = 'GET_DRIVER_ROUTE_SESSIONS';
export const GET_ROUTE_ROUTE_SESSIONS = 'GET_ROUTE_ROUTE_SESSIONS';
export const GET_GENERAL_ROUTE_SESSIONS = 'GET_GENERAL_ROUTE_SESSIONS';
export const GET_GENERAL_STATS = 'GET_GENERAL_STATS';
export const GET_COMMENTS = 'GET_COMMENTS';
export const GET_PRODUCER_DASHBOARD_PICKUP_AND_LAB_DETAILS = 'GET_PRODUCER_DASHBOARD_PICKUP_AND_LAB_DETAILS';
export const GET_SINGLE_INSTANCE = 'GET_SINGLE_INSTANCE';
export const GET_PRODUCER_PRODUCTION_DETAILS = 'GET_PRODUCER_PRODUCTION_DETAILS';
export const CLEAR_PRODUCER_PRODUCTION_DETAILS = 'CLEAR_PRODUCER_PRODUCTION_DETAILS';
export const GET_PRODUCTION_STATS = 'GET_PRODUCTION_STATS';

export const GET_SCHEDULE_DETAILS = 'GET_SCHEDULE_DETAILS';
export const GET_SCHEDULE_CONFIG_DETAILS = 'GET_SCHEDULE_CONFIG_DETAILS';
export const SET_SCHEDULE_PUBLISHED_PROCESSOR = 'SET_SCHEDULE_PUBLISHED_PROCESSOR';
export const GET_ADMIN_SCHEDULE_DETAILS = 'GET_ADMIN_SCHEDULE_DETAILS';
export const GET_PROCESSOR_SCHEDULE_DETAILS = 'GET_PROCESSOR_SCHEDULE_DETAILS';
export const GET_HAULING_SCHEDULE_DETAILS = 'GET_HAULING_SCHEDULE_DETAILS';

export const GET_USER_SETTINGS = 'GET_USER_SETTINGS';
export const GET_USER_REPORT_SCHEDULE = 'GET_USER_REPORT_SCHEDULE';
export const GET_CUSTOM_TABLE_CONFIGURATION = 'GET_CUSTOM_TABLE_CONFIGURATION';
export const GET_ADMIN_CUSTOM_TABLE_CONFIGURATION = 'GET_ADMIN_CUSTOM_TABLE_CONFIGURATION';

export const SET_DATE = 'SET_DATE';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_USING_CUSTOM_DATE_RANGE = 'SET_USING_CUSTOM_DATE_RANGE';
export const SET_CUSTOM_DATE_RANGE_START = 'SET_CUSTOM_DATE_RANGE_START';
export const SET_CUSTOM_DATE_RANGE_END = 'SET_CUSTOM_DATE_RANGE_END';
export const SET_USING_CUSTOM_DATE_PRESETS = 'SET_USING_CUSTOM_DATE_PRESETS';
export const SET_CUSTOM_DATE_PRESET_OPTION = 'SET_CUSTOM_DATE_PRESET_OPTION';

export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';

export const SET_SELECTED_HAULING_CONTRACT_TAB = 'SET_SELECTED_HAULING_CONTRACT_TAB';

// ADMIN ACTIONS ---------------------------------------------------------------------------------------------------------------------------------------------------------------
export function getAdminDashboardDetails(month, year) {
    const { region } = store.getState().persisted.auth.user.data;
    return (dispatch) => repository.getAdminDashboardDetailsNEW(month, year, region).then((payload) => dispatch({ type: GET_ADMIN_DASHBOARD_DETAILS, payload }));
}

export function getAdminEstimatedFluidSalesDetails(month, year) {
    return (dispatch) => repository.getAdminEstimatedFluidSalesDetails(month, year).then((payload) => dispatch({ type: GET_ADMIN_ESTIMATED_FLUID_SALES_DETAILS, payload }));
}

export function getAdminInTransitDetails() {
    return (dispatch) => repository.getAdminInTransitDetails().then((payload) => dispatch({ type: GET_ADMIN_IN_TRANSIT_DETAILS, payload }));
}

export function getAdminOnFarmEstimateDetails() {
    return (dispatch) => repository.getAdminOnFarmEstimateDetails().then((payload) => dispatch({ type: GET_ADMIN_ON_FARM_ESTIMATE_DETAILS, payload }));
}

export function getAdminLabDetails(start, end) {
    return (dispatch) => repository.getAdminLabDetails(start, end).then((payload) => dispatch({ type: GET_ADMIN_LAB_REPORTS, payload }));
}

export function getAdminMonthlyReportsDetails() {
    return (dispatch) =>
        repository
            .getMonthlyReportDetails()
            .then((payload) => dispatch({ type: GET_ADMIN_MONTHLY_REPORTS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getAdminPickupDetails(start, end, filterType = 'milk') {
    return (dispatch) =>
        repository
            .getAdminPickupDetails(start, end, filterType)
            .then((payload) => dispatch({ type: GET_PICKUP_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getComments(type, itemID) {
    return (dispatch) =>
        repository
            .getComments(type, itemID)
            .then((payload) => dispatch({ type: GET_COMMENTS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getDropoffDetails(start, end, filterType = 'milk') {
    return (dispatch) =>
        repository
            .getDropoffDetails(start, end, filterType)
            .then((payload) => dispatch({ type: GET_DROPOFF_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getPickupsProcessorDetails(start, end) {
    return (dispatch) =>
        repository
            .getPickupsProcessorDetails(start, end)
            .then((payload) => dispatch({ type: GET_PICKUPS_PROCESSOR_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getPenaltyStatusDetails(date) {
    return (dispatch) =>
        repository
            .getPenaltyStatusDetails(date)
            .then((payload) => dispatch({ type: GET_PENALTY_STATUS_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getBaseTransferDetails(start, end) {
    const id = getId();
    return (dispatch) =>
        repository
            .getBaseTransferDetails(start, end)
            .then((payload) => dispatch({ type: GET_BASE_TRANSFERS_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getProducerBaseTransferDetails(start, end) {
    const producerId = getId();
    return (dispatch) =>
        repository
            .getBaseTransferDetails(start, end, producerId)
            .then((payload) => dispatch({ type: GET_BASE_TRANSFERS_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getCloseDayDetails({ date }) {
    return (dispatch) =>
        repository
            .getCloseDayDetails({ date })
            .then((payload) => dispatch({ type: GET_CLOSE_DAY_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function putCloseDayDetails(date, isLocked) {
    return (dispatch) =>
        repository
            .putCloseDayDetails(date, isLocked)
            .then((payload) => dispatch({ type: GET_CLOSE_DAY_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getCloseMonthDetails({ isLocked, startDate, endDate }) {
    return (dispatch) =>
        repository
            .getCloseMonthDetails({ isLocked, startDate, endDate })
            .then((payload) => {
                return dispatch({ type: GET_CLOSE_MONTH_DETAILS, payload });
            })
            .catch((error) => {
                throw error;
            });
}

export function putCloseMonthDetails(isLocked, startDate, endDate) {
    return (dispatch) =>
        repository
            .putCloseMonthDetails(isLocked, startDate, endDate)
            .then((payload) => {
                return dispatch({ type: GET_CLOSE_MONTH_DETAILS, payload });
            })
            .catch((error) => {
                throw error;
            });
}

export function getWashDetails(start, end, isHauler) {
    const isSubUser = store.getState().persisted.auth.user.data.subuser;
    const id = isHauler ? (isSubUser ? store.getState().persisted.auth.user.data.hauling_id : store.getState().persisted.auth.user.data.id) : null;
    return (dispatch) =>
        repository
            .getWashDetails(start, end, id)
            .then((payload) => dispatch({ type: GET_WASH_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getWashFacilityDetails() {
    return (dispatch) =>
        repository
            .getWashFacilityDetails()
            .then((payload) => dispatch({ type: GET_WASH_FACILITY_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getRouteSessionDetails(start, end, filterType) {
    return (dispatch) =>
        repository
            .getRouteSessionDetails(start, end, filterType)
            .then((payload) => dispatch({ type: GET_ROUTE_SESSIONS_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getPoolingSessionDetails(start, end, filterType) {
    return (dispatch) =>
        repository
            .getPoolingSessionDetails(start, end, filterType)
            .then((payload) => dispatch({ type: GET_POOLING_SESSION_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getRouteMapping(sessionID) {
    return () => repository.getRouteMapping(sessionID).then((payload) => payload);
}

export function getRouteStats(routeID, startDate, endDate) {
    return (dispatch) =>
        repository
            .getStats({
                page: 'route',
                startDate,
                endDate,
            })
            .then((response) => dispatch({ type: GET_ROUTE_STATS, payload: response }));
}

export function getGeneralStats(routeID, startDate, endDate) {
    return (dispatch) =>
        repository
            .getStats({
                page: 'general',
                startDate,
                endDate,
            })
            .then((response) => dispatch({ type: GET_GENERAL_STATS, payload: response }));
}

export function getDriverStats(driverID, startDate, endDate) {
    return (dispatch) =>
        repository
            .getStats({
                page: 'driver',
                startDate,
                endDate,
            })
            .then((response) => dispatch({ type: GET_DRIVER_STATS, payload: response }));
}

export function getSessionDetails(key, region) {
    return (dispatch) =>
        repository
            .getSessionDetails(key, region)
            .then((payload) => dispatch({ type: GET_SESSION_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getRouteSessionPickups(key) {
    const { region } = store.getState().persisted.auth.user.data;
    return (dispatch) =>
        repository
            .getRouteSessionPickups(key, region)
            .then((payload) => dispatch({ type: GET_ROUTE_SESSION_PICKUPS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getRouteSessionDropoffs(key) {
    const { region } = store.getState().persisted.auth.user.data;
    return (dispatch) =>
        repository
            .getRouteSessionDropoffs(key, region)
            .then((payload) => dispatch({ type: GET_ROUTE_SESSION_DROPOFFS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function updateRouteSessionStatus(key, status) {
    const model = { status };
    const forceLoopback = true;
    return (dispatch) =>
        repository
            .editRouteSession(model, key, forceLoopback)
            .then((payload) => dispatch({ type: UPDATE_ROUTE_SESSION_STATUS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getBactoAlertDetails(selectedMonth, selectedYear) {
    const id = getId();
    const { region } = store.getState().persisted.auth.user.data;
    return (dispatch) =>
        repository
            .getBactoAlertDetails(id, region, selectedMonth, selectedYear)
            .then((payload) => dispatch({ type: GET_BACTO_ALERT_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getMonthlyBactoDetails(date) {
    const id = getId();
    const { region } = store.getState().persisted.auth.user.data;
    return (dispatch) =>
        repository
            .getMonthlyBactoDetails(id, region, date)
            .then((payload) => dispatch({ type: GET_MONTH_BACTO_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getSingleInstance(type, id) {
    return (dispatch) => repository.getSingleInstance(type, id).then((payload) => dispatch({ type: GET_SINGLE_INSTANCE, payload }));
}

export function getRemainingVolumes(sessionID) {
    return () => repository.getRemainingVolume(sessionID).then((payload) => payload);
}

export function getAdminBactoChartDetails(selectedMonth, selectedYear) {
    return (dispatch) =>
        repository
            .getAdminBactoChartDetails(selectedMonth, selectedYear)
            .then((payload) => dispatch({ type: GET_BACTO_CHARTS_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getSingleProducerProductionDetails(producerId, tank) {
    return (dispatch) =>
        repository
            .getSingleProducerProductionDetails(producerId, tank)
            .then((payload) => dispatch({ type: GET_PRODUCER_PRODUCTION_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function clearSingleProducerProductionDetails() {
    return { type: CLEAR_PRODUCER_PRODUCTION_DETAILS };
}

export function getProductionStats() {
    return (dispatch) =>
        repository
            .getProductionStats()
            .then((payload) => dispatch({ type: GET_PRODUCTION_STATS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function downloadADLFile(month, year) {
    return () =>
        repository
            .downloadADLFile(month, year)
            .then((payload) => payload)
            .catch((error) => {
                throw error;
            });
}

export function downloadCDCFile(month, year) {
    return () =>
        repository
            .downloadCDCFile(month, year)
            .then((payload) => payload)
            .catch((error) => {
                throw error;
            });
}

export function downloadMonthlyMilk(month, year) {
    return () =>
        repository
            .downloadMonthlyMilk(month, year)
            .then((payload) => payload)
            .catch((error) => {
                throw error;
            });
}

export function downloadMonthlyMilkPrices(month, year) {
    return () =>
        repository
            .downloadMonthlyMilkPrices(month, year)
            .then((payload) => payload)
            .catch((error) => {
                throw error;
            });
}

export function downloadBordenReport(month, year, mid) {
    return () =>
        repository
            .downloadBordenReport(month, year, mid)
            .then((payload) => payload)
            .catch((error) => {
                throw error;
            });
}

export function downloadScheduleItems(month, year) {
    const { region } = store.getState().persisted.auth.user.data;
    return () =>
        repository
            .downloadScheduleItems(month, year, region)
            .then((payload) => payload)
            .catch((error) => {
                throw error;
            });
}

export function downloadPickupSlips(month, year) {
    return () =>
        repository
            .downloadPickupSlips(month, year)
            .then((payload) => payload)
            .catch((error) => {
                throw error;
            });
}

export function downloadDropoffSlips(month, year) {
    return () =>
        repository
            .downloadDropoffSlips(month, year)
            .then((payload) => payload)
            .catch((error) => {
                throw error;
            });
}

export function downloadMeteredDropoffSlips(month, year) {
    return () =>
        repository
            .downloadMeteredDropoffSlips(month, year)
            .then((payload) => payload)
            .catch((error) => {
                throw error;
            });
}

// PRODUCER ACTIONS ---------------------------------------------------------------------------------------------------------------------------------------------------------------
export function getProducerDashboardDetails(month, year) {
    const { id, region } = store.getState().persisted.auth.user.data;
    return (dispatch) => repository.getProducerDashboardDetailsNEW(id, region, month, year).then((payload) => dispatch({ type: GET_PRODUCER_DASHBOARD_DETAILS, payload }));
}

export function getProducerPickupDetails(start, end, filterType = 'milk') {
    const id = getId();
    return (dispatch) =>
        repository
            .getProducerPickupDetails(id, start, end, filterType)
            .then((payload) => dispatch({ type: GET_PICKUP_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getProducerPickupsLabsDetails(start, end, selectedTank) {
    const id = getId();
    return (dispatch) =>
        repository
            .getProducerPickupsLabsDetails(id, start, end, selectedTank)
            .then((payload) => dispatch({ type: GET_PICKUPS_LABS_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getProducerLabDetails(start, end, months) {
    const id = getId();
    const { region } = store.getState().persisted.auth.user.data;
    return (dispatch) =>
        repository
            .getProducerLabDetails({
                id,
                region,
                start,
                end,
                months,
            })
            .then((payload) => dispatch({ type: GET_PRODUCER_LAB_REPORTS, payload }));
}

export function getProducerMonthlyReportsDetails() {
    const id = getId();
    return (dispatch) =>
        repository
            .getMonthlyReportDetails(id)
            .then((payload) => dispatch({ type: GET_PRODUCER_MONTHLY_REPORTS, payload }))
            .catch((error) => {
                throw error;
            });
}

// HAULING ACTIONS ---------------------------------------------------------------------------------------------------------------------------------------------------------------
export function getHaulingRouteSessionDetails(start, end, filterType = 'milk') {
    const id = getId();
    return (dispatch) =>
        repository
            .getHaulingRouteSessionDetails(id, start, end, filterType)
            .then((payload) => dispatch({ type: GET_HAULING_ROUTE_SESSIONS_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getHaulingRouteSessionDetailsByHauler(start, end, filterType = 'milk') {
    const id = getId();
    return (dispatch) =>
        repository
            .getHaulingRouteSessionDetailsByHauler(id, start, end, filterType)
            .then((payload) => dispatch({ type: GET_HAULING_ROUTE_SESSIONS_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getHaulingDropoffDetails(start, end, filterType = 'milk') {
    const id = getId();
    return (dispatch) =>
        repository
            .getHaulingDropoffDetails(id, start, end, filterType)
            .then((payload) => dispatch({ type: GET_HAULING_DROPOFF_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getHaulingDropoffDetailsByHauler(start, end, filterType = 'milk') {
    const id = getId();
    return (dispatch) =>
        repository
            .getHaulingRouteSessionDetailsByHauler(id, start, end, filterType)
            .then((payload) => dispatch({ type: GET_HAULING_DROPOFF_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getHaulingPickupDetails(start, end, filterType = 'milk') {
    const id = getId();
    return (dispatch) =>
        repository
            .getHaulingPickupDetails(id, start, end, filterType)
            .then((payload) => dispatch({ type: GET_HAULING_PICKUP_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getHaulingPickupDetailsByHauler(start, end, filterType = 'milk') {
    const id = getId();
    return (dispatch) =>
        repository
            .getHaulingRouteSessionDetailsByHauler(id, start, end, filterType)
            .then((payload) => dispatch({ type: GET_HAULING_PICKUP_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getADLPickupsInfo(month, year) {
    return (dispatch) =>
        repository.getADLPickupsInfo(month, year).catch((error) => {
            throw error;
        });
}

// PROCESSOR ACTIONS ---------------------------------------------------------------------------------------------------------------------------------------------------------------
export function getProcessorDashboardDetails(month, year) {
    const id = getId();
    const { region } = store.getState().persisted.auth.user.data;
    return (dispatch) => repository.getProcessorDashboardDetails(id, region, month, year).then((payload) => dispatch({ type: GET_PROCESSOR_DASHBOARD_DETAILS, payload }));
}

export function getProcessorRouteSessionDetails(start, end) {
    const id = getId();
    return (dispatch) =>
        repository
            .getProcessorRouteSessionDetails(id, start, end)
            .then((payload) => dispatch({ type: GET_PROCESSOR_ROUTE_SESSIONS_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getProcessorDropoffDetails(start, end) {
    const id = getId();
    return (dispatch) =>
        repository
            .getProcessorDropoffDetails(id, start, end)
            .then((payload) => dispatch({ type: GET_PROCESSOR_DROPOFF_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getProcessorLabDetails(start, end) {
    const id = getId();
    return (dispatch) =>
        repository
            .getProcessorLabDetails(id, start, end)
            .then((payload) => dispatch({ type: GET_PROCESSOR_LAB_REPORTS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getDFNSReportInfo(month, year) {
    return (dispatch) =>
        repository.getDFNSReportInfo(month, year).catch((error) => {
            throw error;
        });
}

export function setSchedulePublishedProcessor(processorId) {
    return { type: SET_SCHEDULE_PUBLISHED_PROCESSOR, payload: { publishedProcessor: processorId } };
}

// USER SETTINGS ACTIONS ---------------------------------------------------------------------------------------------
export function getUserSettingsDetails(id) {
    return (dispatch) =>
        repository
            .getUserSettings(id)
            .then((payload) => dispatch({ type: GET_USER_SETTINGS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getUserReportScheduleDetails(id) {
    return (dispatch) =>
        repository
            .getUserReportSchedule(id)
            .then((payload) => {
                dispatch({ type: GET_USER_REPORT_SCHEDULE, payload });
            })
            .catch((error) => {
                throw error;
            });
}

export function getCustomTableConfigurationDetails(tableName) {
    return (dispatch) =>
        repository
            .getCustomTableConfiguration(tableName)
            .then((payload) => {
                dispatch({ type: GET_CUSTOM_TABLE_CONFIGURATION, payload });
            })
            .catch((error) => {
                throw error;
            });
}

export function getAdminCustomTableConfigurationDetails(tableName) {
    return (dispatch) =>
        repository
            .getAdminCustomTableConfiguration(tableName)
            .then((payload) => {
                dispatch({ type: GET_ADMIN_CUSTOM_TABLE_CONFIGURATION, payload });
            })
            .catch((error) => {
                throw error;
            });
}

export function setSelectedHaulingContractTab(tab) {
    return { type: SET_SELECTED_HAULING_CONTRACT_TAB, payload: tab };
}

// DATE ACTIONS ---------------------------------------------------------------------------------------------------------------------------------------------------------
export function setDate(date) {
    return { type: SET_DATE, payload: date };
}

export function setStartDate(date) {
    return { type: SET_START_DATE, payload: date };
}

export function setUsingCustomDateRange(truthy) {
    return { type: SET_USING_CUSTOM_DATE_RANGE, payload: truthy };
}

export function setUsingCustomDateRangePresets(truthy) {
    return { type: SET_USING_CUSTOM_DATE_PRESETS, payload: truthy };
}

export function setCustomDatePresetOption(value) {
    return { type: SET_CUSTOM_DATE_PRESET_OPTION, payload: value };
}

export function setCustomDateRangeStart(date) {
    return { type: SET_CUSTOM_DATE_RANGE_START, payload: date };
}

export function setCustomDateRangeEnd(date) {
    return { type: SET_CUSTOM_DATE_RANGE_END, payload: date };
}

// SPLASH SCREEN ACTIONS -------------------------------------------------
export function setLoading() {
    return { type: SET_LOADING };
}

export function setError(error) {
    return { type: SET_ERROR, payload: error };
}
