import axios from 'axios';
import { getEquityMembersQuery } from './queries';
import { buildMembershipQuery } from './queries/builders';

const getEquityUsers = async (id) => {
    try {
        const membershipQuery = `/v1/equityMembers/${id}`;
        const queryOptions = {
            filterDeleted: true,
            filterSubusers: false,
            ids: [id],
            populate: {
                producers: ['license_number', 'name'],
            },
        };

        // mongo query on user collection
        const equityUsersQuery = getEquityMembersQuery(queryOptions);

        // dynamo query on equity-member
        const equityMembershipQuery = buildMembershipQuery(membershipQuery, {
            headers: {},
            data: {},
        });

        const promises = [axios.get(equityUsersQuery), axios.get(equityMembershipQuery.url)];
        const promiseResponse = await Promise.all(promises);

        const equityUser = promiseResponse[0].data[0];
        const equityMembershipData = promiseResponse[1].data.results;

        return {
            ...equityUser,
            ...equityMembershipData,
        };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        throw new Error('Unable to process request.');
    }
};

export default getEquityUsers;
