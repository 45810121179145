import React, { useCallback, useMemo, useEffect } from 'react';
import Region from '../../constants/region';
import { useDispatch } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { showMessage, addHaulingContract, editHaulingContract } from 'app/store/actions';
import MomentUtils from '@date-io/moment';
import _ from 'lodash';
import moment from 'moment-timezone';
import ReactHookFormSearchableSelect from '../form-components/ReactHookFormSearchableSelect';
import { getRateTypesForContract, basis, basisStopCharge, getHaulingTypesBasisParameter, fullMonthSelectOptions, getMilkTypeOptions } from '../form-components/HaulingContractSelectOptions';
import { yupResolver } from '@hookform/resolvers/yup';
import { HaulingContractEditSchema, HaulingContractSchema } from '../form-schemas/HaulingContractSchema';
import HaulingContractDetails from '../form-components/HaulingContractDetails';
import ExistingHaulerRates from '../form-components/ExistingHaulerRates';
import { Button, Grid, Box, makeStyles, debounce, Typography, FormControl, InputAdornment } from '@material-ui/core';
import { filterParentProducers, generateTestId, getUserRegion } from '../../../utils';
import useAsync from 'app/hooks/useAsync';
import getHaulerRatesForHaulingContract from '../../repository/getHaulerRatesForHaulingContract';
import HaulingContractType from 'app/constants/hauling';
import ReactHookFormInput from '../form-components/ReactHookFormInput';
import { Alert, FormHelperText } from '@mui/material';

const DATE_FORMAT = 'MM/DD/yyyy';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

const HaulingContractForm = ({ editData, producers, haulers }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const region = getUserRegion();
    const [parentProducers] = useMemo(() => filterParentProducers(producers), [producers]);

    const type = getRateTypesForContract(region);
    const milkTypesBasisParameter = getMilkTypeOptions(region);
    const haulingTypesBasisParameter = getHaulingTypesBasisParameter(region);

    const reactHookFormMethods = useForm({
        mode: 'all',
        defaultValues: {
            producer: editData?.producer,
            hauler: editData?.hauler,
            hauling_rate_details: editData?.hauling_rate_details?.map((details) => {
                return {
                    ...details,
                    type: _.find(type, { value: details.type }),
                    basis: _.find([...basis, ...basisStopCharge], { value: details.basis }),
                    basis_parameter: _.find([...milkTypesBasisParameter, ...haulingTypesBasisParameter], { value: details.basis_parameter }),
                    paid_by_producer: details.paid_by_producer ?? ![Region.UDA].includes(region),
                    exclude_pickups_before_date: fullMonthSelectOptions.find(({ value }) => value === details?.exclude_pickups_before_date),
                };
            }),
            start_date: editData?.start_date ? moment(editData.start_date) : moment().startOf('month'),
            end_date: editData?.end_date ? moment(editData.end_date) : null,
            pay_report: editData?.pay_report,
            contract_max_charge_per_period: editData?.contract_max_charge_per_period,
        },
        resolver: yupResolver(editData ? HaulingContractEditSchema : HaulingContractSchema),
    });

    const { handleSubmit, control, watch, errors, setValue, formState } = reactHookFormMethods;

    const startDate = watch('start_date');
    const endDate = watch('end_date');
    const haulerRates = watch('haulerRates');
    const hauler = watch('hauler');

    useEffect(() => {
        setValue('haulerRates', null);
    }, [hauler]);

    // useAsync needs to execute a function that returns a promise, if you simply pass undefined it will throw an error
    // This is a dirty fix to always give useAsync a function to execute
    const fakePromiseToMakeUseAsyncHappy = new Promise((resolve, _reject) => {
        resolve();
    });
    const [editDetailsHaulerRates] = useAsync(editData != null ? () => getHaulerRatesForHaulingContract(hauler?.id ?? editData?.hauler?.id, startDate, endDate, editData?.hauler_rates) : () => fakePromiseToMakeUseAsyncHappy, [hauler?.id ?? editData?.hauler?.id, startDate, endDate, editData?.hauler_rates]);

    const transformUserLabel = (option) => {
        const id = option.license_number || option.hauler_number;
        if (id) {
            return `${id} - ${option.full_name || option.name}`;
        }
        if (option.full_name || option.name) {
            return `${option.full_name || option.name}`;
        }
        return '';
    };

    const post = (submitModel) => {
        dispatch(addHaulingContract(submitModel))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Added Hauling Contract' }));
                history.replace({ pathname: '/hauling-contracts' });
            })
            .catch(() => {
                dispatch(showMessage({ message: 'Could not add Hauling Contract' }));
            });
    };

    const put = (submitModel) => {
        const { _id } = editData;
        dispatch(editHaulingContract(submitModel, _id))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Edited Hauling Contract' }));
                history.replace({ pathname: '/hauling-contracts' });
            })
            .catch(() => {
                dispatch(showMessage({ message: 'Could not edit Hauling Contract' }));
            });
    };

    const onSubmit = (model) => {
        const submitModel = {
            ...model,
            hauling_rate_details: model.hauling_rate_details?.map((details) => {
                return {
                    ...details,
                    type: details.type.value,
                    basis: details.basis.value,
                    basis_parameter: details.basis_parameter?.value,
                    min_charge: details.min_charge?.length === 0 ? undefined : details.min_charge,
                    max_charge: details.max_charge?.length === 0 ? undefined : details.max_charge,
                    exclude_pickups_before_date: details.exclude_pickups_before_date?.value,
                };
            }),
            // hauler_rates exist on edit, not on create
            hauler_rates: model.haulerRates != null ? model.haulerRates.map((haulerRate) => haulerRate?._id) : [],
            end_date: model.end_date || undefined,
            contract_type: HaulingContractType.ProducerHaulerContract,
            haulerRates: model?.haulerRates ?? [],
        };
        // Express expects object id fields to be _id but it gets changed to id on the frontend
        // eslint-disable-next-line no-underscore-dangle
        submitModel.producer._id = submitModel.producer.id;
        // eslint-disable-next-line no-underscore-dangle
        submitModel.hauler._id = submitModel.hauler.id;

        if (editData) {
            put(submitModel);
        } else {
            post(submitModel);
        }
    };

    useEffect(() => {
        if (editData != null && editDetailsHaulerRates?.status !== 'pending') {
            setValue('haulerRates', editDetailsHaulerRates.data?.haulerRates);
        }
    }, [editDetailsHaulerRates]);

    const debounceSubmit = useCallback(debounce(onSubmit, 500), []);

    const effectiveDateError = _.get(formState?.errors ?? {}, 'effective_date');
    const render = () => {
        return (
            <div className={classes.root}>
                <FormProvider {...reactHookFormMethods}>
                    <form noValidate onSubmit={handleSubmit(debounceSubmit, errors)}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <ReactHookFormSearchableSelect label={'Hauler'} name={'hauler'} options={haulers.sort((a, b) => collator.compare(a.hauler_number, b.hauler_number))} customRender={transformUserLabel} required />
                            </Grid>

                            <Grid item xs={6}>
                                <ReactHookFormSearchableSelect label={'Producer'} name={'producer'} options={parentProducers.sort((a, b) => collator.compare(a.license_number, b.license_number))} customRender={transformUserLabel} required />
                            </Grid>

                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Controller
                                        name="start_date"
                                        control={control}
                                        value={editData?.start_date || moment()}
                                        render={({ field: { onChange, value } }) => {
                                            return <DatePicker data-testid={generateTestId('Start Date', 'date-picker')} fullWidth onChange={onChange} format={DATE_FORMAT} value={value} label="Start Date" required />;
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Controller
                                        name="end_date"
                                        control={control}
                                        value={editData?.end_date}
                                        defaultValue={null}
                                        render={({ field: { onChange, value } }) => {
                                            return <DatePicker data-testid={generateTestId('End Date', 'date-picker')} fullWidth onChange={onChange} format={DATE_FORMAT} minDate={startDate} value={value} label="End Date" clearable />;
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item xs={6}>
                                <ReactHookFormInput label={'Monthly Max Charge'} name={'contract_max_charge_per_period'} type={'number'} />
                            </Grid>

                            <Grid item xs={24}>
                                {editData && (
                                    <>
                                        <Typography variant={'subtitle2'}>Effective Date *</Typography>
                                        <Grid container spacing={3} style={{ paddingBottom: 20 }}>
                                            <Grid item xs={12} sm={4}>
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <FormControl error={!!effectiveDateError} fullWidth style={{ margin: '8px 0' }}>
                                                        <Controller
                                                            name="effective_date"
                                                            control={control}
                                                            defaultValue={null}
                                                            render={({ field: { onChange, value } }) => {
                                                                return (
                                                                    <DatePicker
                                                                        data-testid={generateTestId('Effective Date', 'date-picker')}
                                                                        openTo="year"
                                                                        label="Date"
                                                                        disableToolbar
                                                                        views={['year', 'month', 'date']}
                                                                        format={'MMM DD, YYYY'}
                                                                        value={value || null}
                                                                        onChange={onChange}
                                                                        required={editData}
                                                                        fullWidth
                                                                        inputVariant={'outlined'}
                                                                        style={{ margin: '8px 0' }}
                                                                        minDate={moment(startDate).isValid() ? moment(startDate).startOf('date') : undefined}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <i className="text-20 material-icons" color="action">
                                                                                        date_range
                                                                                    </i>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {effectiveDateError && <FormHelperText error>{effectiveDateError.message}</FormHelperText>}
                                                    </FormControl>
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} style={{ paddingBottom: 20 }}>
                                            <Grid item xs={12}>
                                                <Alert severity="info">Editing this contract will end the current contract and create a new contract that will be effective on the date above.</Alert>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>

                        <ExistingHaulerRates haulerRatesFromForm={haulerRates?.length === 0 ? [] : haulerRates} />
                        <HaulingContractDetails editData={editData} startDate={startDate} endDate={endDate} region={region} />

                        <Box display="flex" justifyContent="center" gridGap="30px" paddingTop={'30px'}>
                            <Button variant="contained" color="primary" margin="normal" className="mx-auto my-16" sx={{ paddingTop: 2 }} type="submit">
                                Save
                            </Button>
                        </Box>
                    </form>
                </FormProvider>
            </div>
        );
    };
    return <>{render()}</>;
};

export default withRouter(HaulingContractForm);
