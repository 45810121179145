import Region from '../../constants/region';

const type = [
    { value: 'base_hauling', name: 'Base hauling' },
    { value: 'fuel_surcharge', name: 'Fuel surcharge' },
    { value: 'stop_charge', name: 'Stop charge' },
];

const supplementalType = [...type.slice(0, -1), { value: 'stop_charge', name: 'Flat Rate' }];

export const getRateTypesForContract = (region, supplemental = false) => {
    return supplemental && region === Region.MMPA ? supplementalType : type;
};

export const basis = [
    { value: 'fixed', name: 'Fixed' },
    { value: 'cwt', name: 'CWT' },
    { value: 'percent', name: 'Percent' },
    { value: 'per_pickup', name: 'Per Pickup (tank)' },
    { value: 'per_load', name: 'Per Load' },
];

export const basisStopCharge = [
    { value: 'per_pickup', name: 'Per Pickup (tank)' },
    { value: 'per_load', name: 'Per Load' },
    { value: 'per_day_with_pickup', name: 'Per Day With Pickup' },
];

export const milkTypesBasisParameter = [
    { value: 'all', name: 'All Milk' },
    { value: 'a', name: 'Grade A' },
    { value: 'degrade', name: 'Degrade' },
];

export const getMilkTypeOptions = (region) => {
    if ([Region.UNC].includes(region)) {
        return [...milkTypesBasisParameter, { value: 'conventional', name: 'Conventional' }, { value: 'kosher', name: 'Kosher' }, { value: 'organic', name: 'Organic' }];
    }

    return milkTypesBasisParameter;
};

export const getHaulingTypesBasisParameter = (region, supplemental = false) => {
    const totalHaulingBasisParameter = { value: 'total_hauling', name: 'Total Hauling' };
    return supplemental && region === Region.MMPA ? [...supplementalType, totalHaulingBasisParameter] : [...type, totalHaulingBasisParameter];
};

export const pickupRelatedHaulingBasis = new Set(['cwt', 'lbs', 'per_pickup', 'per_load', 'per_day_with_pickup']);

export const fullMonthSelectOptions = Array.from({ length: 31 }, (_currElem, i) => ({
    value: i + 1,
    name: `${i + 1}`,
}));

export const displayMaxMinCharges = (basisSelected) => {
    return ['cwt'].includes(basisSelected?.value);
};

export const displayMonthlyMaxCharges = (basisSelected) => {
    return !['fixed'].includes(basisSelected?.value);
};
