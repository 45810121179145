import React, { useEffect, useState } from 'react';
import { Tooltip, Chip, Checkbox, TextField } from '@mui/material';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { generateTestId } from '../../utils';

const tooltipSx = {
    root: {
        flexGrow: 1,
    },
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SELECT_ALL = { name: 'Select All' };

const hasSelectAllValue = (v) => v.filter((el) => el && el.name === SELECT_ALL.name).length > 0;

export default function SearchableSelectAll({ options, label, value, multiple, disabled, selectAll, onChange, chipLimit, customRender, required, disableClearable, variant, hideCheckbox, hideError, errorText, hideHelper, loading, customGroup }) {
    const [isSelectAll, setIsSelectAll] = useState(false);
    const getValue = () => (!Array.isArray(value) ? value : [...(isSelectAll && !hasSelectAllValue(value) ? [SELECT_ALL] : []), ...value]);
    const getOptions = () => {
        const combinedOptions = [...(selectAll ? [SELECT_ALL] : []), ...options];

        combinedOptions.sort((a, b) => {
            if (a.category < b.category) return -1;
            if (a.category > b.category) return 1;
            return 0;
        });

        return combinedOptions;
    };

    useEffect(() => {
        if (Array.isArray(value) && selectAll) {
            if (value.length === options.length) {
                setIsSelectAll(true);
            }
        }
    }, []);

    const handleChange = (e, v) => {
        if (!Array.isArray(v)) {
            onChange(e, v);
            return;
        }
        const includesSelectAll = hasSelectAllValue(v);
        if (includesSelectAll && !isSelectAll) {
            onChange(e, options);
            setIsSelectAll(true);
        } else if (!includesSelectAll && isSelectAll) {
            onChange(e, []);
            setIsSelectAll(false);
        } else {
            onChange(
                e,
                v.filter((val) => val && val.name !== 'Select All')
            );
            if (v.filter((val) => val && val.name !== 'Select All').length === options.length) {
                setIsSelectAll(true);
            }
            if (v.filter((val) => val && val.name !== 'Select All').length === 0) {
                setIsSelectAll(false);
            }
        }
    };

    const renderTags = (val, getTagProps) => {
        const renderVal = val.filter((el) => el && el.name !== SELECT_ALL.name);
        const numTags = renderVal.length;

        return (
            <>
                {(chipLimit ? renderVal.slice(0, chipLimit) : renderVal).map((option, index) => (
                    <Chip margin="normal" {...getTagProps({ index })} label={option.name} sx={tooltipSx.root} />
                ))}

                {numTags > chipLimit && (
                    <Tooltip
                        title={
                            <>
                                {renderVal.slice(chipLimit).map((option) => {
                                    const index = val.indexOf(option);
                                    return <Chip margin="normal" {...getTagProps({ index })} label={option.name} sx={{ ...tooltipSx.root, backgroundColor: (theme) => theme.palette.primary.contrastText }} />;
                                })}
                            </>
                        }
                        arrow
                    >
                        <Chip label={`+${numTags - chipLimit}`} />
                    </Tooltip>
                )}
            </>
        );
    };

    const optionRenderer = (option, { selected }) => (
        <>
            {!hideCheckbox && <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />}
            {customRender ? customRender(option) : option.name}
        </>
    );

    const getHelperText = () => {
        if (hideError) return undefined;
        if (errorText && typeof errorText === 'string') return errorText;
        if (!hideHelper && required && !value) return `Please select a ${label}`;
        return '';
    };

    const inputRenderer = (params) => <TextField data-testid={generateTestId(label, 'searchable-select-input')} error={errorText && !hideError} helperText={getHelperText()} required={required} {...params} variant={variant || 'standard'} label={label} />;

    return (
        <Autocomplete
            value={getValue()}
            multiple={multiple}
            disabled={disabled}
            options={getOptions()}
            groupBy={(option) => (customGroup ? customGroup(option) : option.category)}
            disableCloseOnSelect={!!multiple}
            disableClearable={disableClearable}
            getOptionLabel={
                customRender
                    ? (option) => customRender(option)
                    : (option) => {
                          return option.name;
                      }
            }
            renderOption={optionRenderer}
            renderTags={renderTags}
            onChange={handleChange}
            renderInput={inputRenderer}
            chipLimit={chipLimit}
            required={required}
            loading={loading}
            data-testid={generateTestId(label, 'searchable-select-all')}
        />
    );
}
