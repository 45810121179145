import ability from './ability';
import { ProcessedFluidPermissions, ReceivingPermissions, ScheduleItems, DropoffPermissions, RouteSessionPermissions, PickupPermissions, HelpfulLinkPermissions, MilkAdjustmentsPermissions } from './features';
import UDACloseMonth from './features/ClosePeriod/UDACloseMonth';

function updateUserPermissions(user) {
    const emptyRules = [];
    const rules = emptyRules.concat(
        ProcessedFluidPermissions(user),
        ScheduleItems(user),
        ReceivingPermissions(user),
        DropoffPermissions(user),
        RouteSessionPermissions(user),
        PickupPermissions(user),
        HelpfulLinkPermissions(user),
        UDACloseMonth(user),
        MilkAdjustmentsPermissions(user)
        // Prettier no prettying
    );

    ability.update(rules);
}

export default updateUserPermissions;
