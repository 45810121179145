import {
    Dashboard,
    ListAdmins,
    ListProducers,
    ListProcessors,
    ListTrailers,
    ListHaulingCompanies,
    ListDrivers,
    OverrideQualityStatus,
    ListScheduleRoutes,
    ManageProcessorTotals,
    ListRouteSessions,
    ListDropoffs,
    ListPickups,
    ListLabReports,
    ListSilos,
    ListCIPWash,
    ListManifests,
    ListUserGroups,
    GetMilkScheduleDetails,
    ListSharedFiles,
    ViewDriverLocations,
    UploadDownload,
    ListReceivers,
    GetFluidScheduleDetails,
    ManageDeferrals,
    ManageAssignees,
    ManageMilkPrices,
    ManageProducerPayReports,
    ListPickupsAndLabs,
    ListMonthlyReports,
    ManageClosePeriod,
    ListInvoicing,
    ManageEquity,
    ManagePoolingDecisions,
    ManageProducerTouchBases,
    ManageMerchandise,
    ManageProducerAssignments,
    ManageHaulerAssignments,
    ManageHaulingCharges,
    ManageHaulingContracts,
    ManageHaulerRates,
    ManageVendorPayReports,
    ManagePaymentStatus,
    ManageHaulerPayReports,
    ListSubAdmins,
    ListSubProducers,
    ListSubProcessors,
    ListSubHauling,
    ViewAccountingReports,
    ViewHaulerPayReport,
    ManageProcessedFluidsPickups,
    ManageDeliveryReports,
    ViewDocumentation,
    ViewReceivingDropoffs,
} from './permissions';
import LABELS from './constants';

const region = 'MMPA';

const SubAdminManagement = {
    label: LABELS.Management,
    permissions: [
        ListAdmins,
        ListProducers,
        ListDrivers,
        ListProcessors(region),
        ListHaulingCompanies(region),
        ListTrailers,
        ListSubAdmins,
        // force wrap
    ],
};

const SubAdminGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ListSilos,
        ListUserGroups,
        ViewDriverLocations,
        ListScheduleRoutes,
        ManageClosePeriod,
        ListPickups(),
        ListDropoffs(),
        ListRouteSessions(region),
        ManageDeliveryReports,
        ListInvoicing,
        ListCIPWash,
        ListLabReports(),
        ManageEquity,
        UploadDownload,
        ListManifests,
        GetMilkScheduleDetails(),
        GetFluidScheduleDetails,
        OverrideQualityStatus,
        ListSharedFiles,
        ManageClosePeriod,
        ViewDocumentation,
        ViewReceivingDropoffs,
    ],
};

const SubAdminPooling = {
    label: LABELS.Pooling,
    permissions: [ManagePoolingDecisions, ManageProcessorTotals, ManageProducerTouchBases],
};

const SubAdminAccounting = {
    label: LABELS.Accounting,
    permissions: [
        ViewAccountingReports,
        ManageMerchandise(region),
        ManageProducerAssignments,
        ManageHaulerAssignments,
        ManageAssignees,
        ManageMilkPrices,
        ManageDeferrals,
        ManageHaulingCharges(),
        ManageHaulingContracts(),
        ManageHaulerRates,
        ManageProducerPayReports,
        ManageHaulerPayReports,
        ManageVendorPayReports,
        ManagePaymentStatus,
        // force wrap
    ],
};

const SubProducerManagement = {
    label: LABELS.Management,
    permissions: [ListSubProducers],
};

const SubProducerGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ListPickups('View', ''),
        ListPickupsAndLabs,
        ListLabReports('View', ''),
        ListSharedFiles,
        // force wrap
    ],
};

const SubProducerAccounting = {
    label: LABELS.Accounting,
    permissions: [ListMonthlyReports(region)],
};

const SubProcessorManagement = {
    label: LABELS.Management,
    permissions: [ListReceivers, ListSubProcessors(region)],
};

const SubProcessorGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ListSilos,
        ListDropoffs('View', ''),
        ManageProcessedFluidsPickups,
        ListRouteSessions(region, 'View', ''),
        ListInvoicing,
        GetMilkScheduleDetails(),
        GetFluidScheduleDetails,
        ListSharedFiles,
        ViewReceivingDropoffs,
        // force wrap
    ],
};

const SubHaulingManagement = {
    label: LABELS.Management,
    permissions: [ListDrivers, ListTrailers, ListSubHauling(region)],
};

const SubHaulingGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ViewDriverLocations,
        ListPickups(),
        ListDropoffs(),
        ListCIPWash,
        ViewHaulerPayReport,
        GetMilkScheduleDetails('View', ''),
        GetFluidScheduleDetails,
        ListSharedFiles,
        ViewReceivingDropoffs,
        // force wrap
    ],
};

const SubAdmin = [SubAdminManagement, SubAdminGeneral, SubAdminAccounting, SubAdminPooling];
const SubProducer = [SubProducerManagement, SubProducerGeneral, SubProducerAccounting];
const SubProcessor = [SubProcessorManagement, SubProcessorGeneral];
const SubHauling = [SubHaulingManagement, SubHaulingGeneral];

const REQUIRED_PERMISSIONS = {
    Admin: [
        ListSilos,
        ListUserGroups,
        ListInvoicing,
        OverrideQualityStatus,
        ListManifests,
        GetMilkScheduleDetails(),
        GetFluidScheduleDetails,
        ListTrailers,
        // force-wrap
    ],
};

const MMPA = {
    Admin: { permissions: SubAdmin, required: REQUIRED_PERMISSIONS.Admin },
    'Sub-Admin': { permissions: SubAdmin },
    'Sub-Producer': { permissions: SubProducer },
    Accountant: { permissions: SubProducer },
    'Feed-Provider': { permissions: SubProducer },
    Nutritionist: { permissions: SubProducer },
    'Co-Owner': { permissions: SubProducer },
    'Sub-Processor': { permissions: SubProcessor },
    'Sub-Hauling': { permissions: SubHauling },
};

export default MMPA;
