import { AbilityBuilder, PureAbility } from '@casl/ability';
import { PROCESSED_FLUID_REGIONS } from '../../../../constants/bulkRegion';
import Region from '../../../../constants/region';

function ProcessedFluidPermissions(user) {
    const { can, cannot, rules } = new AbilityBuilder(PureAbility);
    const {
        role,
        data: { region, id, processor_id },
    } = user;

    if (role === 'admin') {
        can('mutate', 'adjustment');
        can('mutate', 'pickup');
        can('mutate', 'dropoff');
    }

    // Can Create Pickup if origin plant or subuser of origin plant
    can('mutate', 'pickup', { 'origin_processor_id._id': id });
    can('mutate', 'pickup', { 'origin_processor_id._id': processor_id });

    // Can Create Dropoff if destination plant or subuser of destination plant
    can('mutate', 'dropoff', { 'destination_processor_id._id': id });
    can('mutate', 'dropoff', { 'destination_processor_id._id': processor_id });

    // Everyone can view pickups and dropoffs by default
    can('view', 'pickup');
    can('view', 'dropoff');

    if (region === Region.PRAIRIE) {
        can('view', 'adjustment');
        // If logged in as the route session destination, cannot view a pickup
        cannot('view', 'pickup', { 'destination_processor_id._id': id, dropoff_id: null });
        cannot('view', 'pickup', { 'destination_processor_id._id': processor_id, dropoff_id: null });

        // Can mutate adjustment if destination plant or subuser of destination plant
        can('mutate', 'adjustment', { 'destination_processor_id._id': id });
        can('mutate', 'adjustment', { 'destination_processor_id._id': processor_id });
    }

    if (PROCESSED_FLUID_REGIONS.includes(region)) {
        can('view', 'new_processed_fluids');
    }

    return rules;
}

export default ProcessedFluidPermissions;
