import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { Button, Divider, InputAdornment } from '@material-ui/core';
import Formsy from 'formsy-react';
import { showMessage, addSubAdmin, editUser } from 'app/store/actions';
import PermissionSelector from '../../custom-widgets/PermissionsSelector/PermissionSelector';
import { SelectSearchFormsy } from '../../../@fuse/components/formsy';
import { TextFieldFormsy } from '@fuse';
import { formatUserErrorMessage, getUser, getUserRegion } from '../../../utils';
import { Alert } from '@mui/material';
import Region from '../../constants/region';
import UserAlreadyExistsWarningDialog from '../../custom-widgets/dialogs/UserAlreadyExistsWarningDialog';
import { regionStateList, state } from '../form-components/ReactHookProducerFormSelects';
import _ from 'lodash';

const SubAdminForm = ({ editData, admins, preselectedPermissions, disabled }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const region = getUserRegion();
    const [canSubmit, setCanSubmit] = useState(false);
    const [alert, setAlert] = useState(null);
    const [warning, setWarning] = useState(null);
    const currentUser = getUser();
    const basePermissions = editData?.permissions || preselectedPermissions || [];
    const [permissions, setPermissions] = useState(new Set(basePermissions));
    const showError = !permissions.size;
    const isStockHolder = editData?.permissions == null ? false : editData?.permissions.includes('ManageEquity');

    const disableButton = () => {
        setCanSubmit(false);
    };

    const enableButton = () => {
        setCanSubmit(true);
    };

    const handlePermissionChange = (newPermissions) => {
        setPermissions(newPermissions);
    };

    const navigateToManageUsers = () => {
        history.replace({ pathname: '/manage/sub-users' });
    };

    const onSubmit = (model) => {
        const submitModel = { ...model };

        submitModel.permissions = Array.from(permissions);

        if (editData) {
            delete submitModel.username;
            delete submitModel.email;
            switch (editData.role) {
                case 'processor':
                    submitModel.processor_id = submitModel.admin_id;
                    delete submitModel.admin_id;
                    break;
                case 'producer':
                    submitModel.producer_id = submitModel.admin_id;
                    delete submitModel.admin_id;
                    break;
                case 'transport':
                    submitModel.hauling_id = submitModel.admin_id;
                    delete submitModel.admin_id;
                    break;
                default:
                    break;
            }
            dispatch(editUser(submitModel, editData.id))
                .then(() => {
                    dispatch(showMessage({ message: 'Successfully Edited Sub Admin.' }));
                    setAlert(null);
                    navigateToManageUsers();
                })
                .catch((err) => {
                    dispatch(showMessage({ message: formatUserErrorMessage(err, 'Sub Admin', 'edit') }));
                });
        } else {
            submitModel.type_of_fluid = admins ? admins[0].type_of_fluid : 'milk';
            dispatch(addSubAdmin(submitModel))
                .then((response) => {
                    dispatch(showMessage({ message: 'Successfully Added Sub Admin.' }));
                    setAlert(null);
                    if (response?.payload?.data?.warning) {
                        setWarning(response.payload.data.warning);
                    } else {
                        navigateToManageUsers();
                    }
                })
                .catch((err) => {
                    dispatch(showMessage({ message: 'Unable to create a new Sub Admin' }));
                    if (err.message) {
                        setAlert(err.message);
                    }
                });
        }
    };

    const subUserOf = editData ? (editData.role === 'admin' ? editData.admin_id : editData.role === 'producer' ? editData.producer_id : editData.role === 'processor' ? editData.processor_id : editData.role === 'transport' ? editData.hauling_id : '') : '';
    return (
        <div className="min-w-3/4 max-w-3/4">
            <UserAlreadyExistsWarningDialog open={!!warning} onClose={() => navigateToManageUsers()} message={warning} />

            <Divider />

            <Formsy
                onValidSubmit={onSubmit}
                onValid={enableButton}
                onInvalid={disableButton}
                /* eslint-disable-next-line no-return-assign */
                ref={(form) => form}
                className="flex flex-col justify-center"
            >
                {alert && (
                    <Alert className="mt-32 mb-16" color="error">
                        {alert}
                    </Alert>
                )}

                <TextFieldFormsy
                    className="my-16"
                    type="text"
                    name="name"
                    label="Name"
                    value={editData ? editData.name : ''}
                    validations={{ minLength: 2 }}
                    validationErrors={{ minLength: 'Min character length is 2' }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <i className="text-20 material-icons" color="action">
                                    person
                                </i>
                            </InputAdornment>
                        ),
                    }}
                    required
                    variant="outlined"
                />

                <TextFieldFormsy
                    className="my-16"
                    type="text"
                    name="username"
                    label="Username"
                    value={editData ? editData.username : ''}
                    validations={{ ...(!editData && { minLength: 4 }) }}
                    validationErrors={{ minLength: 'Min character length is 4' }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <i className="text-20 material-icons" color="action">
                                    bookmark
                                </i>
                            </InputAdornment>
                        ),
                    }}
                    required
                    variant="outlined"
                    disabled={!!editData}
                />

                {!editData && (
                    <TextFieldFormsy
                        className="my-16"
                        type="password"
                        name="password"
                        label="Password"
                        validations={{ minLength: 8, equalsField: 'password_confirmation' }}
                        validationErrors={{ equalsField: 'Passwords do not match', minLength: 'Password must be at least 8 characters' }}
                        InputProps={{
                            autoComplete: 'new-password',
                            endAdornment: (
                                <InputAdornment position="end">
                                    <i className="text-20 material-icons" color="action">
                                        vpn_key
                                    </i>
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        required
                    />
                )}

                {!editData && (
                    <TextFieldFormsy
                        className="my-16"
                        type="password"
                        name="password_confirmation"
                        label="Confirm Password"
                        validations={{ minLength: 8, equalsField: 'password' }}
                        validationErrors={{ equalsField: 'Passwords do not match', minLength: 'Password must be at least 8 characters' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <i className="text-20 material-icons" color="action">
                                        vpn_key
                                    </i>
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        required
                    />
                )}

                <TextFieldFormsy
                    className="my-16"
                    type="text"
                    name="email"
                    label="Email"
                    value={editData ? editData.email : ''}
                    validations="isEmail"
                    validationError="This is not a valid email"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <i className="text-20 material-icons" color="action">
                                    alternate_email
                                </i>
                            </InputAdornment>
                        ),
                    }}
                    required
                    variant="outlined"
                    disabled={!!editData}
                />

                <TextFieldFormsy
                    className="my-16"
                    type="text"
                    name="secondary_email"
                    label="Secondary Email"
                    validations="isEmail"
                    value={editData ? editData.secondary_email : ''}
                    validationError="This is not a valid email"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <i className="text-20 material-icons" color="action">
                                    alternate_email
                                </i>
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                />

                <SelectSearchFormsy
                    className="my-16"
                    name="admin_id"
                    label={editData ? 'Subuser of: ' : 'Admin'}
                    variant="standard"
                    options={[{ value: ' ', name: 'None' }, ...admins.map((entry) => ({ value: entry.value, name: entry.name }))]}
                    value={editData ? subUserOf : admins.length === 1 ? admins[0].value : currentUser.id}
                    validations="minLength:2"
                    validationError="Please select an Admin"
                />

                {[Region.PRAIRIE].includes(region) && isStockHolder && editData.role === 'producer' && (
                    <>
                        <TextFieldFormsy
                            className="my-16"
                            type="text"
                            name="equity_id"
                            placeholder="Equity ID"
                            label="Equity ID"
                            value={editData ? editData.equity_id : ''}
                            validationErrors={{ minLength: 'Min character length is 2' }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <i className="text-20 material-icons" color="action">
                                            list_alt
                                        </i>
                                    </InputAdornment>
                                ),
                            }}
                            required
                            variant="outlined"
                        />
                        <TextFieldFormsy
                            className="my-16"
                            type="text"
                            name="mailing_address1"
                            label={'Mailing Address Line 1'}
                            placeholder={'Mailing Address Line 1'}
                            value={editData ? editData.mailing_address1 : ''}
                            validations={{ minLength: 2 }}
                            validationError="Please enter a valid address"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <i className="text-21 material-icons" color="action">
                                            home
                                        </i>
                                    </InputAdornment>
                                ),
                            }}
                            required
                            variant="outlined"
                        />
                        <TextFieldFormsy
                            className="my-16"
                            type="text"
                            name="mailing_address2"
                            label="Mailing Address Line 2"
                            placeholder="Mailing Address Line 2"
                            value={editData ? editData.mailing_address2 : ''}
                            validations={{ minLength: 2 }}
                            validationError="Please enter a valid address"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <i className="text-21 material-icons" color="action">
                                            home
                                        </i>
                                    </InputAdornment>
                                ),
                            }}
                            required
                            variant="outlined"
                        />
                        <TextFieldFormsy
                            className="my-16"
                            type="text"
                            name="mailing_city"
                            label={'Mailing City'}
                            placeholder={'Mailing City'}
                            value={editData ? editData.mailing_city : ''}
                            validations={{ minLength: 2 }}
                            validationError="Please enter a valid mailing city"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <i className="text-21 material-icons" color="action">
                                            place
                                        </i>
                                    </InputAdornment>
                                ),
                            }}
                            required
                            variant="outlined"
                        />
                        <SelectSearchFormsy
                            className="my-16"
                            name="mailing_state"
                            label="Mailing State"
                            placeholder={'Mailing State'}
                            value={editData ? editData.mailing_state : ''}
                            options={_.isEmpty(regionStateList(region)) ? state : regionStateList(region)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <i className="text-21 material-icons" color="action">
                                            place
                                        </i>
                                    </InputAdornment>
                                ),
                            }}
                            required
                            variant="outlined"
                        />
                        <TextFieldFormsy
                            className="my-16"
                            type="text"
                            name="mailing_zip"
                            label={'Mailing Postal / ZIP Code'}
                            placeholder={'Mailing Postal / ZIP Code'}
                            value={editData ? editData.mailing_zip : ''}
                            validations="minLength: 4"
                            validationError="Please enter a valid postal code"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <i className="text-21 material-icons" color="action">
                                            mail_outline
                                        </i>
                                    </InputAdornment>
                                ),
                            }}
                            required
                            variant="outlined"
                        />
                    </>
                )}

                <PermissionSelector
                    permissionType={editData ? (editData.role === 'producer' ? 'Sub-Producer' : editData.role === 'processor' ? 'Sub-Processor' : editData.role === 'transport' ? 'Sub-Hauling' : 'Sub-Admin') : 'Sub-Admin'}
                    handlePermissionChange={handlePermissionChange}
                    /* eslint no-unneeded-ternary:0 */
                    // prettier-ignore
                    selectedValues={permissions}
                    disabled={disabled}
                    showError={showError}
                />

                <Button type="submit" variant="contained" color="primary" className="mx-auto my-16" aria-label={editData ? 'EDIT SUB USER' : 'ADD SUB USER'} disabled={disabled || !canSubmit}>
                    {editData ? 'Edit Sub User' : 'Add Sub User'}
                </Button>
            </Formsy>
        </div>
    );
};

export default withRouter(SubAdminForm);
