import {
    Dashboard,
    ListAdmins,
    ListProducers,
    ListProcessors,
    ListTrailers,
    ListHaulingCompanies,
    ListDrivers,
    ListRouteSessions,
    ListDropoffs,
    ListPickups,
    ListLabReports,
    ListSilos,
    ManageCloseMonth,
    ListCIPWash,
    ListUserGroups,
    GetMilkScheduleDetails,
    ListSharedFiles,
    ViewDriverLocations,
    UploadDownload,
    ListReceivers,
    ManageMilkPrices,
    ManageProducerPayReports,
    ListBaseTransfers,
    ListPickupsAndLabs,
    ListMonthlyReports,
    ListBactoAlert,
    ListScheduleRoutes,
    ListSubAdmins,
    ListSubProducers,
    ListSubProcessors,
    ListSubHauling,
    ListTrucks,
    ManageRapidTest,
    QARapidTest,
    ViewAccountingReports,
    EditSiloInvoices,
    ViewReceivingDropoffs,
    ManageDeliveryReports,
    ViewDocumentation,
    ListInvoicing,
    ManageHaulingCharges,
    ManageHaulingContracts,
    ManageHaulerRates,
    ManageHaulerPayReports,
    ManageAssignees,
    ManageHaulerAssignments,
    GetFluidScheduleDetails,
    ManageProcessedFluidsPickups,
    ManageProcessedFluidsDropoffs,
    ManageProcessedFluidsRouteSessions,
} from './permissions';
import LABELS from './constants';

const region = 'UDA';

const SubAdminManagement = {
    label: LABELS.Management,
    permissions: [
        ListAdmins,
        ListProducers,
        ListDrivers,
        ListProcessors(region),
        ListHaulingCompanies(region),
        ListTrailers,
        ListTrucks,
        ListSubAdmins,
        // force wrap
    ],
};

const SubAdminGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ListSilos,
        EditSiloInvoices,
        ViewDocumentation,
        ListUserGroups,
        ViewDriverLocations,
        ListScheduleRoutes,
        ManageCloseMonth,
        ListPickups(),
        ManageProcessedFluidsPickups,
        ListDropoffs(),
        ManageProcessedFluidsDropoffs,
        ListCIPWash,
        ListLabReports(),
        UploadDownload,
        ListRouteSessions(region),
        ManageProcessedFluidsRouteSessions,
        ManageDeliveryReports,
        GetMilkScheduleDetails(),
        GetFluidScheduleDetails,
        QARapidTest,
        ManageRapidTest,
        ViewReceivingDropoffs,
        ListSharedFiles,
        ListInvoicing,
        // force wrap
    ],
};

const SubAdminAccounting = {
    label: LABELS.Accounting,
    permissions: [
        ManageMilkPrices,
        ListBaseTransfers(region),
        ManageProducerPayReports,
        ViewAccountingReports,
        ManageHaulingCharges(),
        ManageHaulingContracts(),
        ManageHaulerRates,
        ManageHaulerPayReports,
        ManageAssignees,
        ManageHaulerAssignments,
        // force-wrap
    ],
};

const SubProducerManagement = {
    label: LABELS.Management,
    permissions: [ListSubProducers],
};

const SubProducerGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ListPickups('View', ''),
        ListPickupsAndLabs,
        ListLabReports('View', ''),
        UploadDownload,
        ListBactoAlert(region),
        ListSharedFiles,
        // force wrap
    ],
};

const SubProducerAccounting = {
    label: LABELS.Accounting,
    permissions: [ListMonthlyReports(region)],
};

const SubProcessorManagement = {
    label: LABELS.Management,
    permissions: [ListReceivers, ListSubProcessors(region)],
};

const SubProcessorGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ListSilos,
        EditSiloInvoices,
        ListDropoffs('View', ''),
        ManageProcessedFluidsDropoffs,
        ManageProcessedFluidsPickups,
        ManageProcessedFluidsRouteSessions,
        ListLabReports(),
        ListRouteSessions(region, 'View', ''),
        GetMilkScheduleDetails('Manage', '(View, Create Demand)'),
        GetFluidScheduleDetails,
        ViewReceivingDropoffs,
        ListSharedFiles,
        // force wrap
    ],
};

const SubHaulingManagement = {
    label: LABELS.Management,
    permissions: [ListTrailers, ListTrucks, ListSubHauling(region)],
};

const SubHaulingGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ListDrivers,
        ViewDriverLocations,
        ListPickups(),
        ManageProcessedFluidsPickups,
        ListDropoffs(),
        ManageProcessedFluidsDropoffs,
        ManageProcessedFluidsRouteSessions,
        ListCIPWash,
        UploadDownload,
        GetMilkScheduleDetails('View', ''),
        ViewReceivingDropoffs,
        ListSharedFiles,
        // force wrap
    ],
};

const SubAdmin = [SubAdminManagement, SubAdminGeneral, SubAdminAccounting];
const SubProducer = [SubProducerManagement, SubProducerGeneral, SubProducerAccounting];
const SubProcessor = [SubProcessorManagement, SubProcessorGeneral];
const SubHauling = [SubHaulingManagement, SubHaulingGeneral];

const REQUIRED_PERMISSIONS = {
    Admin: [
        ListTrucks,
        ListSilos,
        ListUserGroups,
        ViewReceivingDropoffs,
        GetMilkScheduleDetails(),
        ListTrailers,
        // force-wrap
    ],
};

const UDA = {
    Admin: { permissions: SubAdmin, required: REQUIRED_PERMISSIONS.Admin },
    'Sub-Admin': { permissions: SubAdmin },
    'Sub-Producer': { permissions: SubProducer },
    Accountant: { permissions: SubProducer },
    'Feed-Provider': { permissions: SubProducer },
    Nutritionist: { permissions: SubProducer },
    'Co-Owner': { permissions: SubProducer },
    'Sub-Processor': { permissions: SubProcessor },
    'Sub-Hauling': { permissions: SubHauling },
};

export default UDA;
