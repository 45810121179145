import axios from 'axios';
import Split from '../../Split';
import { createLabReportQuery } from './queries';
import { isLabAlreadyExistsMsg } from '../../utils';
import { buildExpressQuery } from './queries/builders';

const addLabReport = (model) =>
    new Promise((resolve, reject) => {
        const useExpressEndpoint = Split.getBooleanTreatment('use_express_lab_endpoint');

        const query = createLabReportQuery({ model, ...(useExpressEndpoint && { queryBuilder: buildExpressQuery }) });
        const request = axios.post(query.url, { ...query.body });

        request
            .then((response) => {
                resolve({ data: response.data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                const {
                    response: {
                        data: {
                            error: { statusCode, message },
                        },
                    },
                } = errors;
                if (statusCode === 405 && isLabAlreadyExistsMsg(message)) {
                    resolve(new Error(message));
                }
                reject(new Error('Unable to add lab report'));
            });
    });

export default addLabReport;
