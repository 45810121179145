import React, { useContext, useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { MapContext } from './MapContext';

const sanitizeCenter = (center) => {
    if (!center) return center;
    const { lat, lng } = center;
    if (!lat || !lng) return center;
    return { lat: Number(lat), lng: Number(lng) };
};

/**
 * Map component is the component that contains the Google Map instance
 *

 * @param {Object} props
 * @param {Object} props.center {lat: number | string, lng: number | string}
 * @param {Object} props.mapOptions map options
 * @param {string} props.onDragEnd
 * @param {function} props.setMapInstance function to expose the map instance to parent components
 * @param {string} props.children

 * @returns Map component
 */
const BareMap = ({
    children,
    ...mapOptions
    // Force Line Wrap Comment
}) => {
    const { isLoaded } = useJsApiLoader({
        id: 'script-loader',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
        language: 'en',
        region: 'us',
        libraries: ['drawing', 'places'],
    });
    const { setMap } = useContext(MapContext);
    const [center, setCenter] = useState(sanitizeCenter(mapOptions.center));

    useEffect(() => {
        setCenter(sanitizeCenter(mapOptions.center));
    }, [mapOptions.center.lat, mapOptions.center.lng]);

    if (!isLoaded) return null;

    return (
        <GoogleMap
            {...mapOptions}
            center={center}
            onLoad={(...props) => {
                if (setMap) setMap(...props);
                if (mapOptions.onLoad) mapOptions.onLoad(...props);
            }}
            // wrap
        >
            {children}
        </GoogleMap>
    );
};

export default BareMap;
