import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Divider, FormGroup, FormControlLabel, Checkbox, InputAdornment, TextField, MenuItem, Box } from '@material-ui/core';
import Formsy from 'formsy-react';
import { bindActionCreators } from 'redux';
import * as FuseActions from 'app/store/actions';
import * as Actions from '../../store/actions';
import { TextFieldFormsy } from '@fuse';
import { formatUserErrorMessage, getDefaultLatitude, getDefaultLongitude, getFMMOSForRegion, getGoogleMapsURL, PlantQualifiedStatus, toTitleCase, getUser as getUserData } from '../../../utils';
import { LocalizationConsumer } from '../../localization/LocalizationContext';
import ProcessorMonthlyPoolingInformationSelects from '../form-components/ProcessorMonthlyPoolingInformationSelects';
import moment from 'moment-timezone';
import _, { cloneDeep } from 'lodash';
import { getUser } from '../../repository';
import FMMO from '../../constants/pooling';
import Split from '../../../Split';
import { compareDates, directionalMapCompare } from '../../../util-files/compare-utils';
import { Alert, Typography } from '@mui/material';
import UserAlreadyExistsWarningDialog from '../../custom-widgets/dialogs/UserAlreadyExistsWarningDialog';
import { SelectSearchFormsy } from '../../../@fuse/components/formsy';
import GeofenceManager from '../geofences/maps/GeofenceManager/GeofenceManager';
import { DRIVER_LOCATION_REGIONS } from '../../constants/bulkRegion';
import Region from '../../constants/region';

const getDateFromHistoryObject = (obj) => {
    return moment(obj.date).toDate();
};

const directionalDateSort = directionalMapCompare(getDateFromHistoryObject, compareDates);

class CreateCreateProducerForm extends Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        // eslint-disable-next-line react/state-in-constructor
        this.state = {
            canSubmit: false,
            alert: null,
            warning: null,
            latitude: getDefaultLatitude(props.region),
            longitude: getDefaultLongitude(props.region),
            license_number: '',
            federal_order: '',
            pooledChecked: false,
            isRBSTProcessor: null,
            fmmoOptions: getFMMOSForRegion(props.region),
            fmmo_history: [],
            milk_class_history: [],
            poolingStatusByMonth: {},
            milkClassesByMonth: {},
            processor_pooling_fmmo: '',
            plant_type: '',
            requires_rapid_test: false,
            requires_pump_off: false,
            enable_mobile_receiver_ui: false,
            enable_mobile_rapid_test_ui: false,
            geofences: [],
            addCircle: false,
            editRequiresGeofence: false,
            plant_qualified_status: null,
            processorName: null,
        };
    }

    async componentDidMount() {
        const { getProcessorListDetails, region } = this.props;
        let { editData } = this.props;
        const isExpressRequired = Split.getBooleanTreatment('can_access_pooling');

        if (editData) {
            this.setState({
                latitude: editData.latitude,
                longitude: editData.longitude,
                license_number: editData.license_number,
                federal_order: editData.federal_order,
                pooledChecked: editData.pooled_handler,
                isRBSTProcessor: editData?.rBST_free_program_processor || null,
                plant_type: editData?.plant_type || null,
                requires_pump_off: editData.requires_pump_off || false,
                requires_rapid_test: editData.requires_rapid_test || false,
                enable_mobile_receiver_ui: editData.enable_mobile_receiver_ui || false,
                enable_mobile_rapid_test_ui: editData.enable_mobile_rapid_test_ui || false,
                plant_qualified_status: editData.plant_qualified_status,
            });
        }
        getProcessorListDetails(null, false, isExpressRequired).then((response) => {
            // prettier-ignore
            const processorsFilter = !!editData ?
                (value) => !value.deleted_at && value.id !== editData.id :
                (value) => !value.deleted_at
            const otherProcessors = response.payload.processors.filter(processorsFilter);
            this.setState({ otherProcessors });
        });

        // this logic exists due to an issue with redux. When a form is refreshed, the data is now always returned properly from redux.
        // This can cause issues for pooling, since history properties depend on the input value to create the output value.
        // Because of this, added safeguarding was added to ensure that no history objects are accidentally overwritten
        // with a "fake" history.
        const isPoolingRegion = Split.getBooleanTreatment('can_access_pooling');
        const isProcessorIdPresent = (editData?.id ?? editData?._id) != null;

        if (isPoolingRegion && this.isPoolingDataMissing(editData) && isProcessorIdPresent) {
            const id = editData.id ?? editData._id;
            const processor = await getUser(id);
            editData = { ...editData, ...processor };
        }

        if (isPoolingRegion && editData != null) {
            this.setState({
                processor_pooling_fmmo: editData.processor_pooling_fmmo != null ? Number(editData.processor_pooling_fmmo) : '',
                fmmo_history: editData.fmmo_history ?? [],
                milk_class_history: editData.milk_class_history ?? [],
                poolingStatusByMonth: this.buildPoolingStatusByMonthFromEditData(editData),
                milkClassesByMonth: this.buildMilkClassesByMonthFromEditData(editData),
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { editData } = this.props;
        if (prevProps.editData !== editData) {
            if (editData) {
                this.setState({
                    latitude: editData.latitude,
                    longitude: editData.longitude,
                    license_number: editData.license_number,
                    federal_order: editData.federal_order,
                    pooledChecked: editData.pooled_handler,
                    isRBSTProcessor: editData?.rBST_free_program_processor || null,
                    fmmo_history: editData.fmmo_history ?? [],
                    processor_pooling_fmmo: editData.processor_pooling_fmmo != null ? Number(editData.processor_pooling_fmmo) : null,
                    milk_class_history: editData.milk_class_history ?? [],
                    plant_type: editData.plant_type ?? null,
                    requires_pump_off: editData.requires_pump_off || false,
                    requires_rapid_test: editData.requires_rapid_test || false,
                    enable_mobile_receiver_ui: editData.enable_mobile_receiver_ui || false,
                    enable_mobile_rapid_test_ui: editData.enable_mobile_rapid_test_ui || false,
                });
            }
        }
    }

    handleMapChange = (latitude, longitude) => {
        this.setState({ latitude, longitude });
    };

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    enableButton = () => {
        this.setState({ canSubmit: true });
    };

    navigateToListUsers = () => {
        const { history } = this.props;
        history.replace({ pathname: '/list-processors' });
    };

    onSubmit = async (model) => {
        const {
            latitude,
            longitude,
            editData,
            editUser,
            addProcessor,
            showMessage,
            pooledChecked,
            isRBSTProcessor,
            region,
            processor_pooling_fmmo,
            poolingStatusByMonth,
            milkClassesByMonth,
            requires_rapid_test,
            enable_mobile_receiver_ui,
            enable_mobile_rapid_test_ui,
            requires_pump_off,
            geofences,
            plant_qualified_status,
            // wrap
        } = { ...this.state, ...this.props };
        const submitModel = { ...model };

        delete submitModel.location;
        delete submitModel.pooled;

        submitModel.latitude = latitude.toString();
        submitModel.longitude = longitude.toString();
        submitModel.pooled_handler = pooledChecked;
        submitModel.plant_qualified_status = plant_qualified_status;
        submitModel.requires_rapid_test = requires_rapid_test;
        submitModel.enable_mobile_receiver_ui = enable_mobile_receiver_ui;
        submitModel.enable_mobile_rapid_test_ui = enable_mobile_rapid_test_ui;
        submitModel.requires_pump_off = requires_pump_off;

        if (region === 'UDA') {
            submitModel.rBST_free_program_processor = !!isRBSTProcessor;
            submitModel.rBST_processor_charge = !!isRBSTProcessor ? parseFloat(submitModel.rBST_processor_charge) : 0;
            submitModel.rBST_processor_share = !!isRBSTProcessor ? parseFloat(submitModel.rBST_processor_share) : 0;
        }

        if (Split.getBooleanTreatment('can_access_pooling')) {
            try {
                const fmmo_history = this.buildUpdatedFmmoHistoryArray(poolingStatusByMonth, processor_pooling_fmmo);
                const milk_class_history = this.buildUpdatedMilkClassHistoryArray(milkClassesByMonth);

                if (!_.isEmpty(fmmo_history)) {
                    submitModel.fmmo_history = fmmo_history;
                }
                if (!_.isEmpty(milk_class_history)) {
                    submitModel.milk_class_history = milk_class_history;
                }

                const currentlyPooledFmmo = processor_pooling_fmmo != null && processor_pooling_fmmo !== '' ? Number(processor_pooling_fmmo) : null;
                if (currentlyPooledFmmo != null) {
                    submitModel.processor_pooling_fmmo = currentlyPooledFmmo;
                }
            } catch (error) {
                await showMessage({ message: 'Processor cannot be marked as Pooled without selecting a Federal Milk Marketing Order.' });
                return;
            }
        }

        if (geofences.length > 0) {
            const fence = geofences[0];
            fence.name = `${submitModel.name} - ${fence.type}`;
            delete fence._id;
            submitModel.geofence_payload = fence;

            // Update the user model to reflect the new geofence
            submitModel.latitude = fence.center.coordinates[1].toString();
            submitModel.longitude = fence.center.coordinates[0].toString();
            submitModel.geofence_radius = fence.radius_meters;
        }

        if (editData) {
            const id = editData.id ?? editData._id;
            const isExpressRequired = Split.getBooleanTreatment('can_access_pooling');
            delete submitModel.username;
            delete submitModel.email;
            delete submitModel.geofence_payload;

            editUser(submitModel, id, isExpressRequired)
                .then(() => {
                    showMessage({ message: 'Successfully Edited Processor.' });
                    this.setState({ alert: null });
                    this.navigateToListUsers();
                })
                .catch((err) => {
                    showMessage({ message: formatUserErrorMessage(err, 'Processor', 'edit') });
                });
        } else {
            addProcessor(submitModel)
                .then((response) => {
                    showMessage({ message: 'Successfully Added Processor.' });
                    this.setState({ alert: null });
                    if (response?.payload?.data?.warning) {
                        this.setState({ warning: response.payload.data.warning });
                    } else {
                        this.navigateToListUsers();
                    }
                })
                .catch((err) => {
                    showMessage({ message: 'Unable to create a new Processor' });
                    if (err.message) {
                        this.setState({ alert: err.message });
                    }
                });
        }
    };

    handleToggleChange = (name) => (event) => {
        this.setState({ [name]: event.target.checked });
    };

    buildPoolingStatusByMonthFromEditData = (editData) => {
        const poolingStatusByMonth = {};

        const { fmmo_history } = editData;

        if (fmmo_history == null) {
            return {};
        }

        fmmo_history.forEach((poolingStatusDecision) => {
            const poolingStatusMoment = moment(poolingStatusDecision.date);
            const poolingStatusMonthString = poolingStatusMoment.format('YYYY-MM');

            poolingStatusByMonth[poolingStatusMonthString] = {
                isPooled: this.isProcessorPooledForMonth(poolingStatusDecision),
                date: poolingStatusDecision.date,
                is_locked: poolingStatusDecision.is_locked,
            };
        });
        return poolingStatusByMonth;
    };

    isProcessorPooledForMonth = (poolingStatusDecision) => {
        const isProcessorPooled = !_.isEmpty(poolingStatusDecision.fmmos) && !poolingStatusDecision.fmmos.includes(FMMO.Nonpooled);

        return isProcessorPooled;
    };

    buildMilkClassesByMonthFromEditData = (editData) => {
        const milkClassesByMonth = {};

        const { milk_class_history } = editData;

        if (milk_class_history == null) {
            return {};
        }

        milk_class_history.forEach((milkClassesForMonth) => {
            const monthString = milkClassesForMonth.date;

            milkClassesByMonth[monthString] = milkClassesForMonth;
        });
        return milkClassesByMonth;
    };

    buildUpdatedFmmoHistoryArray = (poolingStatusByMonth, selectedPoolingFmmo) => {
        const fmmo_history = Object.entries(poolingStatusByMonth).map(([monthString, poolingStatusDecision]) => {
            if (poolingStatusDecision.isPooled && (selectedPoolingFmmo == null || selectedPoolingFmmo === '')) {
                throw new Error('Cannot set processor as pooled if no processor FMMO is selected.');
            }

            return {
                date: poolingStatusDecision.date,
                fmmos: poolingStatusDecision.isPooled ? [selectedPoolingFmmo] : [FMMO.Nonpooled],
                is_locked: poolingStatusDecision.is_locked ?? false,
            };
        });
        fmmo_history.sort(directionalDateSort.asc);

        return fmmo_history;
    };

    buildUpdatedMilkClassHistoryArray = (milkClassesByMonth) => {
        const milk_class_history = Object.values(milkClassesByMonth).map((milkClassesForMonth) => {
            return milkClassesForMonth;
        });

        milk_class_history.sort(directionalDateSort.asc);

        return milk_class_history;
    };

    handleProcessorPoolingFmmoChange = (event) => {
        const selectedFmmo = event.target.value;
        this.setState({ processor_pooling_fmmo: selectedFmmo });
    };

    handlePlantQualifiedStatusChange = (event) => {
        const selectedStatus = event.target.value;
        if (selectedStatus === 'none') {
            this.setState({ plant_qualified_status: null });
        } else {
            this.setState({ plant_qualified_status: selectedStatus });
        }
    };

    shouldPoolingFormFieldsRender = (region, editData) => {
        const isPoolingRegion = Split.getBooleanTreatment('can_access_pooling');
        const isProcessorIdPresent = (editData?._id ?? editData?.id) != null;

        return isPoolingRegion && isProcessorIdPresent;
    };

    setPoolingStatusForMonth = (newPoolingStatus, monthString) => {
        this.setState((prevState) => {
            const updatedPoolingStatusByMonth = prevState.poolingStatusByMonth;
            updatedPoolingStatusByMonth[monthString] = newPoolingStatus;
            return { poolingStatusByMonth: updatedPoolingStatusByMonth };
        });
    };

    setMilkClassesForMonth = (newMilkClasses, monthString) => {
        this.setState((prevState) => {
            const updatedMilkClassesByMonth = prevState.milkClassesByMonth;
            updatedMilkClassesByMonth[monthString] = newMilkClasses;

            return { milkClassesByMonth: updatedMilkClassesByMonth };
        });
    };

    isPoolingDataMissing = (editData) => {
        return !editData || !editData.fmmo_history || !editData.milk_class_history || !editData.processor_pooling_fmmo;
    };

    handlePlantTypeChange = (event) => {
        this.setState({ plant_type: event.target.value });
    };

    handleEditCircleFromMap = (newGeofences) => {
        this.setState({ geofences: [...newGeofences] });
    };

    render() {
        const { canSubmit, latitude, longitude, otherProcessors, pooledChecked, isRBSTProcessor } = this.state;
        const { editData, region } = this.props;

        return (
            <LocalizationConsumer>
                {(localization) => (
                    <div className="min-w-3/4 max-w-3/4">
                        <UserAlreadyExistsWarningDialog open={!!this.state.warning} onClose={() => this.navigateToListUsers()} message={this.state.warning} />

                        <Divider />

                        <Formsy
                            onValidSubmit={this.onSubmit}
                            onValid={this.enableButton}
                            onInvalid={this.disableButton}
                            /* eslint-disable-next-line no-return-assign */
                            ref={(form) => (this.form = form)}
                            className="flex flex-col justify-center"
                        >
                            {this.state.alert && (
                                <Alert className="mt-32 mb-16" color="error">
                                    {this.state.alert}
                                </Alert>
                            )}

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="name"
                                placeholder="Processor Name"
                                label="Name"
                                value={editData ? editData.name : ''}
                                onChange={(e) => this.setState({ processorName: e.target.value })}
                                validations={{ minLength: 2 }}
                                validationErrors={{ minLength: 'Min character length is 2' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                person
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                            />

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="username"
                                placeholder="Username"
                                label="Username"
                                value={editData ? editData.username : ''}
                                validations={{ ...(!editData && { minLength: 4 }) }}
                                validationErrors={{ minLength: 'Min character length is 4' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                bookmark
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                                disabled={!!editData}
                            />

                            {!editData && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="password"
                                    name="password"
                                    label="Password"
                                    placeholder="Password"
                                    validations={{ minLength: 8, equalsField: 'password_confirmation' }}
                                    validationErrors={{ equalsField: 'Passwords do not match', minLength: 'Password must be at least 8 characters' }}
                                    InputProps={{
                                        autoComplete: 'new-password',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    vpn_key
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                    required
                                />
                            )}

                            {!editData && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="password"
                                    name="password_confirmation"
                                    label="Confirm Password"
                                    placeholder="Confirm Password"
                                    validations={{ minLength: 8, equalsField: 'password' }}
                                    validationErrors={{ equalsField: 'Passwords do not match', minLength: 'Password must be at least 8 characters' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    vpn_key
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                    required
                                />
                            )}

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="email"
                                label="Email"
                                placeholder="Email"
                                validations="isEmail"
                                validationError="This is not a valid email"
                                value={editData ? editData.email : ''}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                alternate_email
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                                disabled={!!editData}
                            />

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="secondary_email"
                                label="Secondary Email"
                                validations="isEmail"
                                value={editData ? editData.secondary_email : ''}
                                validationError="This is not a valid email"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                alternate_email
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                            />

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="license_number"
                                label={localization.general.license_number}
                                placeholder={localization.general.license_number}
                                value={editData ? editData.license_number : ''}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                list_alt
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                            />
                            {[Region.CDI, Region.LEGACY].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="federal_order"
                                    label="Federal Order"
                                    placeholder="Federal Order"
                                    value={editData ? editData.federal_order : ''}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    list_alt
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}

                            {[Region.CDI, Region.LEGACY].includes(region) && (
                                <FormGroup row>
                                    <FormControlLabel control={<Checkbox value="pooled_handler" checked={this.state.pooledChecked} onChange={this.handleToggleChange('pooledChecked')} />} label="Pooled?" />
                                </FormGroup>
                            )}

                            {region === Region.UDA && (
                                <>
                                    <FormControlLabel control={<Checkbox checked={isRBSTProcessor} value onChange={this.handleToggleChange('isRBSTProcessor')} />} label="rBST Free Program?" />
                                    {isRBSTProcessor && (
                                        <>
                                            <TextFieldFormsy className="my-16" name="rBST_processor_charge" label="rBST Charge" variant="outlined" required value={editData?.rBST_processor_charge ? editData.rBST_processor_charge : 0} validations={{ isNumeric: true }} validationError="Please enter an rBST Charge" />
                                            <TextFieldFormsy className="my-16" name="rBST_processor_share" label="rBST Share" variant="outlined" required value={editData?.rBST_processor_share ? editData.rBST_processor_share : 0} validations={{ isNumeric: true }} validationError="Please enter an rBST Share" />{' '}
                                        </>
                                    )}
                                </>
                            )}

                            {Split.getBooleanTreatment('can_manage_mobile_app_processor_workflow') && (
                                <>
                                    <Typography variant="subtitle2" sx={{ marginTop: (theme) => theme.spacing(2) }}>
                                        Mobile App Workflow
                                    </Typography>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={this.state.requires_rapid_test} value onChange={this.handleToggleChange('requires_rapid_test')} />} label="Requires Rapid Test?" />
                                        <FormControlLabel control={<Checkbox checked={this.state.requires_pump_off} value onChange={this.handleToggleChange('requires_pump_off')} />} label="Requires Pump Off?" />
                                        <FormControlLabel control={<Checkbox checked={this.state.enable_mobile_receiver_ui} value onChange={this.handleToggleChange('enable_mobile_receiver_ui')} />} label="Enable Mobile Receiver UI?" />
                                        {region === Region.LEGACY && <FormControlLabel control={<Checkbox checked={this.state.enable_mobile_rapid_test_ui} value onChange={this.handleToggleChange('enable_mobile_rapid_test_ui')} />} label="Enable Mobile Rapid Test UI?" />}
                                    </FormGroup>
                                </>
                            )}

                            {this.shouldPoolingFormFieldsRender(region, editData) && (
                                <>
                                    <TextField select label={'Plant Qualified Status'} value={this.state.plant_qualified_status ?? 'none'} onChange={this.handlePlantQualifiedStatusChange} variant={'outlined'}>
                                        <MenuItem key={0} value={'none'}>
                                            None
                                        </MenuItem>
                                        {Object.values(PlantQualifiedStatus).map((status) => (
                                            <MenuItem key={status} value={status}>
                                                {toTitleCase(status, '_')}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField className="my-16" labelId={'processor-fmmo-label'} value={this.state.processor_pooling_fmmo} onChange={this.handleProcessorPoolingFmmoChange} placeholder={'Federal Milk Marketing Order'} label={'Federal Milk Marketing Order'} select variant={'outlined'}>
                                        {this.state.fmmoOptions
                                            .filter((fmmo) => fmmo > 0)
                                            .map((fmmo) => (
                                                <MenuItem value={fmmo} key={fmmo}>
                                                    {fmmo}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                    <ProcessorMonthlyPoolingInformationSelects
                                        poolingStatusesByMonth={this.state.poolingStatusByMonth}
                                        milkClassesByMonth={this.state.milkClassesByMonth}
                                        setPoolingStatusForMonth={(newPoolingStatus, monthString) => this.setPoolingStatusForMonth(newPoolingStatus, monthString)}
                                        setMilkClassesForMonth={(newMilkClasses, monthString) => this.setMilkClassesForMonth(newMilkClasses, monthString)}
                                    />
                                </>
                            )}

                            {region === Region.UDA && (
                                <SelectSearchFormsy
                                    className="my-16"
                                    name="plant_type"
                                    label="Plant Type"
                                    options={[
                                        { value: 'scaled', name: 'Scaled' },
                                        { value: 'metered', name: 'Metered' },
                                    ]}
                                    value={editData ? editData.plant_type : this.state.plant_type}
                                    onChange={(value) => this.handlePlantTypeChange(value)}
                                    variant="outlined"
                                />
                            )}
                            <Box display={'flex'}>
                                <GeofenceManager
                                    center={{ lat: latitude, lng: longitude }}
                                    onChange={this.handleEditCircleFromMap}
                                    maxFences={1}
                                    user_id={editData?.id ?? editData?._id}
                                    // Force Line Indentation
                                />
                            </Box>
                            <Button type="submit" variant="contained" color="primary" className="mx-auto my-16" aria-label={`${editData ? 'Edit' : 'Add'} ${localization.general.processor_singular}`.toUpperCase()} disabled={!canSubmit}>
                                {`${editData ? 'Edit' : 'Add'} ${localization.general.processor_singular}`}
                            </Button>
                        </Formsy>
                    </div>
                )}
            </LocalizationConsumer>
        );
    }
}

function mapStateToProps({ persisted }) {
    return { region: persisted.auth.user.data.region };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addProcessor: Actions.addProcessor,
            editUser: Actions.editUser,
            showMessage: FuseActions.showMessage,
            getProcessorListDetails: Actions.getProcessorListDetails,
        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateCreateProducerForm));
