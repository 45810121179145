import React, { useContext } from 'react';
import { AppBar, Toolbar, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NavbarMobileToggleButton from 'app/fuse-layouts/shared-components/NavbarMobileToggleButton';
import NotificationAPIComponent from 'app/custom-components/NotificationAPIComponent';
import { getId } from '../../../../utils';
import { NotificationContext } from '../../../NotificationContext';

const AppBarSx = {
    bgcolor: 'background.paper',
    position: 'relative',
};
const ToolbarSx = {
    display: 'flex',
    flexDirection: 'column',
};
const ToolbarGridSx = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
};
const NavbarMobileToggleCompensationSx = {
    // width of NavbarMobileToggleButton
    width: 64,
};

const ToolbarLayout = ({ layoutConfig }) => {
    const notMobile = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const userId = getId();
    const { toolbarContent } = useContext(NotificationContext);
    const { title, subtitle, content } = toolbarContent || {};
    const { mobileOnly = false } = layoutConfig?.toolbar || {};

    return (
        <AppBar id="fuse-toolbar" className="flex relative z-10" sx={AppBarSx}>
            <Toolbar
                sx={{
                    ...ToolbarSx,
                    ...(notMobile && { pl: '40px' }),
                    ...(mobileOnly && !!notMobile && { display: 'none' }),
                    //
                }}
                disableGutters
            >
                <Grid sx={ToolbarGridSx} container>
                    {layoutConfig.navbar.display && layoutConfig.navbar.position === 'left' && !notMobile ? (
                        <Grid item>
                            <NavbarMobileToggleButton>
                                <MenuIcon color="primary" />
                            </NavbarMobileToggleButton>
                            <Divider orientation="vertical" flexItem />
                        </Grid>
                    ) : null}

                    <Grid item xs>
                        <Typography variant="h3" color="textPrimary">
                            {title}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            {subtitle}
                        </Typography>
                    </Grid>

                    <Grid>
                        <Divider orientation="vertical" flexItem color="primary" />

                        <NotificationAPIComponent userId={userId} />
                    </Grid>

                    {layoutConfig.navbar.display && layoutConfig.navbar.position === 'right' && !notMobile && (
                        <Grid>
                            <NavbarMobileToggleButton>
                                <MenuIcon color="primary" />
                            </NavbarMobileToggleButton>
                        </Grid>
                    )}
                </Grid>
                {content && (
                    <Grid container>
                        {layoutConfig.navbar.display && layoutConfig.navbar.position === 'left' && !notMobile ? <Grid item sx={NavbarMobileToggleCompensationSx} /> : null}
                        <Grid item xs>
                            {content}
                        </Grid>
                    </Grid>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default ToolbarLayout;
