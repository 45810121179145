import { object, number, date, array, string } from 'yup';
import { fixedBasis, dumpedMilkBasis, grossAndNetPayBasis, percentBasis } from '../form-components/AssigneeSelects';

export const AssignmentConfigurationSchema = object({
    vendor: object().required('Assignee is a required field').typeError('Assignee is a required field'),
    description: string().optional().nullable(),
    filter: object().required(),
    start_date: date().required('Please provide a start date'),
    end_date: date().optional().nullable(),
    exclude_pickups_before_date: object().optional().nullable(),
    updated_from: string().optional(),
    payment_type: array().min(1, 'Payment Type is a required field'),
    basis: object().required('Basis is a required field').typeError('Basis is a required field'),
    category: object().required('Category is a required field').typeError('Category is a required field'),
    payment_rate: number()
        .when('basis', {
            is: (basis) => basis?.value && !fixedBasis.has(basis.value),
            then: (schema) => schema.required('Payment Rate is a required field').typeError('Payment Rate is a required field'),
            otherwise: (schema) => schema.optional().nullable().typeError('Payment Rate must be a number'),
        })
        .when('basis', {
            is: (basis) => percentBasis.has(basis?.value),
            then: (schema) => schema.required('Payment Rate is a required field').min(0, 'Payment Rate must be between 0-100.').max(100, 'Payment Rate must be between 0-100.').typeError('Payment Rate is a required field'),
        }),
    monetary_value: number().when('basis', {
        is: (basis) => fixedBasis.has(basis?.value),
        then: (schema) => schema.required('Monetary Value is a required field').typeError('Monetary Value is a required field'),
        otherwise: (schema) => schema.optional().nullable().typeError('Monetary Value must be a number'),
    }),
    milk_amount: number().when('basis', {
        is: (basis) => dumpedMilkBasis.has(basis?.value),
        then: (schema) => schema.required('Milk Amount is a required field').typeError('Milk Amount is a required field'),
        otherwise: (schema) => schema.optional().nullable().typeError('Milk Amount must be a number'),
    }),
    milk_type: object().when('basis', {
        is: (basis) => basis?.value && !dumpedMilkBasis.has(basis.value) && !fixedBasis.has(basis.value) && !grossAndNetPayBasis.has(basis.value) && !basis.value === 'weekly_average_previous_month_permanent_deductions',
        then: (schema) => schema.required('Milk Type is a required field').typeError('Milk Type is a required field'),
        otherwise: (schema) => schema.optional().nullable(),
    }),
    day_of_week: object().when('basis', {
        is: (basis) => basis?.value === 'weekly_average_previous_month_permanent_deductions',
        then: (schema) => schema.required('Day of the week is a required field').typeError('Day of the week is a required field'),
        otherwise: (schema) => schema.optional().nullable(),
    }),

    assignmentFilters: array().optional(),
    effective_date: date().optional().nullable(),
    account: string().optional().nullable(),
    reference: string().optional().nullable(),
    priority: number().typeError('Please Enter A Number').positive('Priority must be greater than 0').required('Priority is a required field'),
});

export const AssignmentConfigurationEditSchema = AssignmentConfigurationSchema.concat(object({ effective_date: date().required('Effective Date is a required field') }));
