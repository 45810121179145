import moment from 'moment-timezone';
import axios from 'axios';
import _ from 'lodash';
import { batchQuery, getProcessorsQuery, bulkQuery } from './queries';
import { arrayToMap, renameKey } from '../../utils';

const getCurrentProcessorLocations = () =>
    new Promise((resolve, reject) => {
        const processorQuery = getProcessorsQuery({
            fields: ['id', 'created_at', 'full_name', 'latitude', 'longitude', 'license_number'],
            filterDeleted: true,
        });

        const processorRequest = axios.get(processorQuery);
        processorRequest
            .then((processorResponse) => {
                const processors = processorResponse.data;
                const locations = _.map(processors, (processor) => ({
                    created_at: processor.created_at,
                    processor_name: processor.full_name,
                    license_number: processor.license_number,
                    processor_id: processor.id,
                    latitude: processor.latitude,
                    longitude: processor.longitude,
                }));
                resolve({ locations, success: true });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getCurrentProcessorLocations;
