import axios from 'axios';
import _ from 'lodash';
import { getProducersQuery, getLastPickupsQuery, getHaulingCompaniesQuery, getDriversQuery } from './queries';
import { getTimeSinceLastPickup } from './utils';

const getProducerListDetails = ({ filterDeleted = true, filterInactive, includePickups, filterSplitProducer } = {}) =>
    new Promise((resolve, reject) => {
        const queryProducers = getProducersQuery({ filterDeleted, filterInactive, filterSplitProducer });
        const queries = [axios.get(queryProducers)];

        if (includePickups) {
            const queryPickups = getLastPickupsQuery();
            queries.push(axios.get(queryPickups));
            const queryDrivers = getDriversQuery({ fields: ['id', 'hauling_id'] });
            queries.push(axios.get(queryDrivers));
            const queryHaulers = getHaulingCompaniesQuery({ fields: ['id', 'full_name', 'hauler_number'] });
            queries.push(axios.get(queryHaulers));
        }

        Promise.all(queries)
            .then((response) => {
                let producers = _.orderBy(response[0].data, ['name'], ['asc']);

                if (includePickups) {
                    const pickups = _.reduce(
                        response[1].data,
                        (map, pickup) => {
                            return { ...map, [pickup.producer_id]: pickup };
                        },
                        {}
                    );

                    const drivers = _.reduce(
                        response[2].data,
                        (map, driver) => {
                            return { ...map, [driver.id]: driver };
                        },
                        {}
                    );

                    const haulers = _.reduce(
                        response[3].data,
                        (map, hauler) => {
                            return { ...map, [hauler.id]: hauler };
                        },
                        {}
                    );

                    producers = _.map(producers, (producer) => {
                        const p = { ...producer };
                        if (pickups[producer.id]) {
                            p.pickup_date = pickups[producer.id].created_at;
                            p.pickup_days = getTimeSinceLastPickup(pickups[producer.id]);

                            const driver = drivers[pickups[producer?.id]?.driver_id];
                            p.hauler_num = haulers[driver?.hauling_id]?.hauler_number;
                            p.hauler_name = haulers[driver?.hauling_id]?.full_name;
                        }
                        return p;
                    });
                }

                resolve({ producers });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getProducerListDetails;
