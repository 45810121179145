import Region from '../../../app/constants/region';
import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';

const prod = new Environment({
    name: stages.PRODUCTION,
    regions: [Region.DEMO],
});

const demo = new Environment({
    name: stages.DEMO,
    regions: [Region.UNC, Region.DEMO],
});

const staging = new Environment({
    name: stages.STAGING,
    regions: [Region.UNC, Region.DEMO],
});

const local = new Environment({
    name: stages.LOCAL,
    regions: [Region.UNC, Region.DEMO],
});

const dev = new Environment({
    name: stages.DEV,
    regions: [Region.UNC, Region.DEMO],
});

const can_use_weekly_pay_frequency = new MockSplit({
    name: 'can_use_weekly_pay_frequency',
    environments: [local, dev, staging, demo, prod],
});

export default can_use_weekly_pay_frequency;
