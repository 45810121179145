import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';

/**
 * Direct traffic towards either express or loopback when editing or adding route sessions
 */

const prod = new Environment({
    name: stages.PRODUCTION,
    evaluationFn: (attributes) => {
        if (['NL', 'PEI', 'RF', 'UDA', 'CDI'].includes(attributes.region)) return 'off';
        return 'on';
    },
});

const local = new Environment({
    ...prod,
    name: stages.LOCAL,
});

const dev = new Environment({
    ...prod,
    name: stages.DEV,
});

const staging = new Environment({
    ...prod,
    name: stages.STAGING,
});

const demo = new Environment({
    ...prod,
    name: stages.DEMO,
});

const use_express_lab_endpoint = new MockSplit({
    name: 'use_express_lab_endpoint',
    environments: [local, dev, staging, demo, prod],
});

export default use_express_lab_endpoint;
