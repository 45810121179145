import React from 'react';
import _ from 'lodash';
import Region from 'app/constants/region';
import { Button } from '@material-ui/core';
import { PayReportDownloadStatus } from 'app/constants/report';
import { getUserRegion } from '../../../utils';

const usesCheckNumbers = (region) => [Region.PRAIRIE, Region.MMPA].includes(region);

const PayReportDownloadButtons = ({ runDownloadAll, setCheckNumberDialogOpen, setDialogOpen, paymentType, payReports }) => {
    const region = getUserRegion();

    const checkIfReportsAreApproved = () => {
        return payReports[paymentType]?.some((report) => report?.report_data?.[`approved_${paymentType}_report`] === true);
    };

    const handleDownloadAll = (payReportDownloadStatus) => {
        // Prevent check numbers from being reassigned to approved reports
        const reportsAreApproved = checkIfReportsAreApproved();
        if (!reportsAreApproved && usesCheckNumbers(region) && payReportDownloadStatus === PayReportDownloadStatus.download) {
            setCheckNumberDialogOpen(true);
        } else {
            runDownloadAll(payReportDownloadStatus);
        }
    };

    return (
        <div>
            <Button color="secondary" variant="contained" className="mr-12" disabled={checkIfReportsAreApproved()} onClick={() => handleDownloadAll(PayReportDownloadStatus.calculate)}>
                Calculate Pay
            </Button>
            <Button color="secondary" variant="contained" disabled={checkIfReportsAreApproved()} onClick={() => handleDownloadAll(PayReportDownloadStatus.download)}>
                Download All
            </Button>
            <Button color="secondary" variant="contained" className="ml-12" disabled={checkIfReportsAreApproved()} onClick={() => setDialogOpen(true)}>
                Approve All
            </Button>
        </div>
    );
};

export default PayReportDownloadButtons;
