// eslint-disable-next-line import/prefer-default-export
export const PAYMENT_TYPES = {
    SETTLEMENT: 'SETTLEMENT',
    ADVANCE: 'ADVANCE',
    SETTLEMENT_AND_ADVANCE: 'SETTLEMENT,ADVANCE',
};

/**
 * TODO: Remove this once all regions are using invoicing v2.
 * We had to do it this because splits require a user to be loaded before they work and this was causing issues when reloading and navigating between pages.
 */

export const canUseInvoicingV2 = !window.location.href.includes('mmpa.milk');
