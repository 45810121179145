import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import DeleteDialog from './DeleteDialog';
import RestoreDialog from './RestoreDialog';
import SlipDialog from './SlipDialog';
import MonthlyReportDialog from './MonthlyReportDialog';
import LoadingDialog from './LoadingDialog';
import PoolingDialog from './PoolingDialog';
import ViewDeliveryReportDialog from './ViewDeliveryReportDialog';
import * as Actions from '../../store/actions';
import { getAPIHost, toSnakeCase } from '../../../utils';
import { getFileURL } from '../../repository';
import SensitiveInfoDialog from './SensitiveInfoDialog';
import TaxSensitiveInfoDialog from './TaxSensitiveInfoDialog';
import MatchLabDialog from './MatchLabDialog';

const PaperComponent = (props) => (
    // TODO: Fix draggability such that it does not cause things to break on mobile
    // <Draggable>
    <Paper {...props} />
    // </Draggable>
);

const DataTableDialog = ({ open, onClose, actions, history, rowData, forceFetch, setEditReducer, setInspectReducer, useEditId }) => {
    const [showDialog, setShowDialog] = useState(false);
    const id = rowData?.id || rowData?._id;

    const handleEdit = () => {
        setEditReducer(rowData);

        if (useEditId) {
            history.push({ pathname: `${actions.edit.redirectURL}/${id}` });
        } else {
            history.push({ pathname: actions.edit.redirectURL });
        }
    };

    const handleEditManifest = () => {
        history.push({ pathname: `${actions.editManifest.redirectURL}/${id}` });
    };

    const handleInspect = () => {
        setInspectReducer(rowData);

        if (useEditId) {
            history.push({ pathname: `${actions.inspect.redirectURL}/${id}` });
        } else {
            history.push({ pathname: actions.inspect.redirectURL });
        }
        // history.push({ pathname: actions.inspect.redirectURL });
    };

    const handleInspectPickup = () => {
        history.push({ pathname: `${actions.inspectPickup.redirectURL}/${id}` });
    };

    const handleInspectLabReport = () => {
        history.push({ pathname: `${actions.inspectLabReport.redirectURL}/${rowData?.lab_id}` });
    };

    const handleInspectRouteSession = () => {
        if (rowData?.route_session_id) {
            history.push({ pathname: `${actions.inspectRouteSession.redirectURL}/${rowData.route_session_id}` });
        } else {
            history.push({ pathname: `${actions.inspectRouteSession.redirectURL}/${id}` });
        }
    };

    const handleInspectProcessedFluidTransfer = () => {
        history.push({ pathname: `${actions.inspectPFTransfer.redirectURL}/${id}` });
    };
    // open modal and pass in defaultValues from rowData here
    const handleEditModal = (callback) => {};

    const handleEditItemModal = () => {
        onClose();
        actions.editItemModal.onClick(rowData);
    };

    const handleCompleteIPDOModal = () => {
        onClose();
        actions.completeIPDOModal.onClick(rowData);
    };

    const disableCompleteIPDOModal = () => {
        return rowData?.status !== 'Departure';
    };

    const handleEditIPDOModal = () => {
        onClose();
        actions.editIPDOModal.onClick(rowData);
    };

    const disableEditIPDOModal = () => {
        return !rowData?.receiver_id;
    };

    const handleInspectItemModal = () => {
        onClose();
        actions.inspectItemModal.onClick(rowData);
    };

    const handleInspectProcessedFluidRouteSession = () => {
        history.push({ pathname: `${actions.inspectPFRouteSession.redirectURL}/${id}` });
    };

    const handleEditProcessedFluidRouteSession = () => {
        const formattedRowData = { ...rowData };
        formattedRowData.commodity_type = toSnakeCase(rowData.commodity_type);
        actions.editPFRouteSession.toggleForm();
        actions.editPFRouteSession.setSelectedItem(formattedRowData);
        onClose();
    };

    const handleEditProcessedFluidTransfer = () => {
        const formattedRowData = { ...rowData };
        formattedRowData.commodity_type = toSnakeCase(rowData.commodity_type);
        actions.editPFTransfer.editForm();
        actions.editPFTransfer.setSelectedItem(formattedRowData);
        onClose();
    };

    const handleEditSampleBarcodeBatch = () => {
        onClose();
        actions.editSampleBarcodeBatch.openModal(rowData);
    };

    const handleInspectSampleBarcodeBatch = () => {
        history.push({ pathname: `${actions.inspectSampleBarcodeBatch.redirectURL}/${id}` });
    };

    const handlePrintBarcodeQueue = async () => {
        await actions.printBarcodeQueue.action(rowData);
        onClose();
    };

    const handleCancelBarcodeQueue = async () => {
        await actions.cancelBarcodeQueue.action(rowData);
        onClose();
    };

    const handleDownload = (data) => {
        setShowDialog(true);
        if (!data) {
            return;
        }

        if (data.attachment) {
            getFileURL(`MonthlyReport/${data.attachment}`)
                .then((result) => {
                    const pdfToLoad = result;
                    window.open(pdfToLoad, '_blank');
                    setShowDialog(false);
                })
                .catch((error) => {
                    // console.warn(error);
                });
        } else {
            axios
                .get(`${getAPIHost()}/download-monthly-report/${data.id}`)
                .then((response) => {
                    getFileURL(response.data.Location)
                        .then((result) => {
                            const pdfToLoad = result;
                            window.open(pdfToLoad, '_blank');
                        })
                        .catch((error) => {
                            // console.warn(error);
                        });
                })
                .catch((error) => {
                    // console.warn(error);
                })
                .finally(() => {
                    setShowDialog(false);
                });
        }
    };

    const toggleLoading = (loadingBool) => {
        setShowDialog(loadingBool);
    };

    const showEditButton = () => {
        if (Object.keys(rowData).length === 0) return;
        if (actions.showEditButton) return actions.showEditButton(rowData);
        return true;
    };

    const disableEditButton = () => {
        return rowData.is_locked;
    };

    const disableDeleteButton = () => {
        return rowData.is_locked;
    };

    const disableMatchLabButton = () => {
        return rowData.is_locked;
    };

    const showDeleteButton = () => {
        if (Object.keys(rowData).length === 0) return;
        if (actions.showDeleteButton /* Potentially edit this condition */) return actions.showDeleteButton(rowData);
        return !rowData.deleted_at;
    };

    if (showDialog) {
        return <LoadingDialog showDialog={showDialog} />;
    }

    return (
        <Dialog open={open} onClose={onClose} PaperComponent={PaperComponent} fullWidth maxWidth="sm" aria-labelledby="draggable-dialog-title">
            <DialogTitle id="draggable-dialog-title">Selection Options</DialogTitle>
            <DialogContent>
                <div
                    style={{
                        margin: 'auto',
                        width: 'fit-content',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {actions.slip && (
                        <SlipDialog
                            data={rowData}
                            type={actions.slip.type}
                            shouldAutoOpen={false} // when should we auto open?
                        />
                    )}
                    {actions.viewDeliveryReport && (
                        <ViewDeliveryReportDialog
                            data={rowData}
                            title={actions.viewDeliveryReport.title}
                            //
                        />
                    )}
                    {actions.matchLab && <MatchLabDialog data={rowData} shouldAutoOpen={false} forceFetch={forceFetch} disabled={actions.matchLab.disable || disableMatchLabButton()} />}
                    {actions.inspect && (
                        <Button variant="contained" color="primary" className="my-16" aria-label={actions.inspect.title} onClick={() => handleInspect()}>
                            {actions.inspect.title}
                        </Button>
                    )}
                    {actions.inspectRouteSession && (
                        <Button variant="contained" color="primary" className="my-16" aria-label={actions.inspectRouteSession.title} onClick={() => handleInspectRouteSession()}>
                            {actions.inspectRouteSession.title}
                        </Button>
                    )}
                    {actions.inspectPFRouteSession && (
                        <Button variant="contained" color="primary" className="my-16" aria-label={actions.inspectPFRouteSession.title} onClick={() => handleInspectProcessedFluidRouteSession()}>
                            {actions.inspectPFRouteSession.title}
                        </Button>
                    )}
                    {actions.editPFRouteSession && !actions.editPFRouteSession.showForm && (
                        <Button variant="contained" color="primary" className="my-16" aria-label={actions.editPFRouteSession.title} onClick={() => handleEditProcessedFluidRouteSession()}>
                            {actions.editPFRouteSession.title}
                        </Button>
                    )}
                    {actions.inspectPickup && (
                        <Button variant="contained" color="primary" className="my-16" aria-label={actions.inspectPickup.title} onClick={() => handleInspectPickup()}>
                            {actions.inspectPickup.title}
                        </Button>
                    )}
                    {actions.inspectPFTransfer && (
                        <Button variant="contained" color="primary" className="my-16" aria-label={actions.inspectPFTransfer.title} onClick={() => handleInspectProcessedFluidTransfer()}>
                            {actions.inspectPFTransfer.title}
                        </Button>
                    )}
                    {actions.editPFTransfer && (
                        <Button variant="contained" color="primary" className="my-16" aria-label={actions.editPFTransfer.title} onClick={() => handleEditProcessedFluidTransfer()}>
                            {actions.editPFTransfer.title}
                        </Button>
                    )}
                    {actions.inspectLabReport && (
                        <Button variant="contained" color="primary" className="my-16" aria-label={actions.inspectLabReport.title} onClick={() => handleInspectLabReport()}>
                            {actions.inspectLabReport.title}
                        </Button>
                    )}
                    {actions.inspectSampleBarcodeBatch && (
                        <Button variant="contained" color="primary" className="my-16" aria-label={actions.inspectSampleBarcodeBatch.title} onClick={() => handleInspectSampleBarcodeBatch()}>
                            {actions.inspectSampleBarcodeBatch.title}
                        </Button>
                    )}
                    {actions.edit && showEditButton() && (
                        <Button variant="contained" color="primary" className="my-16" aria-label={actions.edit.title} onClick={() => handleEdit()} disabled={actions.edit.disable || disableEditButton()}>
                            {actions.edit.title}
                        </Button>
                    )}
                    {actions.editManifest && !rowData.is_locked && (
                        <Button variant="contained" color="primary" className="my-16" aria-label={actions.editManifest.title} onClick={() => handleEditManifest()}>
                            {actions.editManifest.title}
                        </Button>
                    )}
                    {actions.inspectItemModal && (
                        <Button variant="contained" color="primary" className="my-16" aria-label={actions.inspectItemModal.title} onClick={() => handleInspectItemModal()}>
                            {actions.inspectItemModal.title}
                        </Button>
                    )}
                    {actions.dialogOptions &&
                        Array.isArray(actions.dialogOptions) &&
                        actions.dialogOptions.map((dialogOption) => (
                            <Button
                                variant="contained"
                                color="primary"
                                aria-label={dialogOption.title}
                                onClick={() => {
                                    dialogOption.setSelected(rowData);
                                    dialogOption.setOpen(true);
                                    onClose();
                                }}
                            >
                                {typeof dialogOption.title === 'function' ? dialogOption.title(rowData) : dialogOption.title}
                            </Button>
                        ))}
                    {actions.editItemModal && (
                        <Button variant="contained" color="primary" className="my-16" aria-label={actions.editItemModal.title} onClick={() => handleEditItemModal()}>
                            {actions.editItemModal.title}
                        </Button>
                    )}
                    {actions.completeIPDOModal && (
                        <Button variant="contained" color="primary" className="my-16" aria-label={actions.completeIPDOModal.title} disabled={disableCompleteIPDOModal()} onClick={() => handleCompleteIPDOModal()}>
                            {actions.completeIPDOModal.title}
                        </Button>
                    )}
                    {actions.editIPDOModal && (
                        <Button variant="contained" color="primary" className="my-16" aria-label={actions.editIPDOModal.title} disabled={disableEditIPDOModal()} onClick={() => handleEditIPDOModal()}>
                            {actions.editIPDOModal.title}
                        </Button>
                    )}
                    {actions.editModal && (
                        <Button variant="contained" color="primary" className="my-16" aria-label={actions.editModal.title} onClick={() => handleEditModal(actions.editModal.onClick)}>
                            {actions.editModal.title}
                        </Button>
                    )}
                    {actions.editSampleBarcodeBatch && (
                        <Button variant="contained" color="primary" className="my-16" aria-label={actions.editSampleBarcodeBatch.title} onClick={() => handleEditSampleBarcodeBatch()}>
                            {actions.editSampleBarcodeBatch.title}
                        </Button>
                    )}
                    {actions.printBarcodeQueue && (
                        <Button variant="contained" color="primary" className="my-16" aria-label={actions.printBarcodeQueue.title} onClick={handlePrintBarcodeQueue}>
                            {actions.printBarcodeQueue.title}
                        </Button>
                    )}
                    {actions.cancelBarcodeQueue && (
                        <Button variant="contained" color="primary" className="my-16" aria-label={actions.cancelBarcodeQueue.title} onClick={handleCancelBarcodeQueue}>
                            {actions.cancelBarcodeQueue.title}
                        </Button>
                    )}
                    {actions.login && (
                        <div>
                            <Button variant="contained" color="primary" className="my-16" aria-label={actions.login.title} onClick={() => actions.login.handler('Log in as', rowData)}>
                                {actions.login.title}
                            </Button>
                        </div>
                    )}
                    {actions.report && <MonthlyReportDialog data={rowData} />}
                    {actions.download && (
                        <div>
                            <Button variant="contained" color="primary" className="my-16" aria-label={actions.download.title} onClick={() => handleDownload(rowData)}>
                                {actions.download.title}
                            </Button>
                        </div>
                    )}
                    {actions.delete && showDeleteButton() && <DeleteDialog title={actions.delete.title} dataType={actions.delete.dataType} data={rowData} forceFetch={forceFetch} disable={actions.delete.disable || disableDeleteButton()} />}
                    {actions.restore && !!rowData.deleted_at && <RestoreDialog title={actions.restore.title} dataType={actions.restore.dataType} data={rowData} forceFetch={forceFetch} />}
                    {actions.pooled && <PoolingDialog data={rowData} forceFetch={forceFetch} toggleLoading={toggleLoading} />}
                    {actions.editSensitive && <SensitiveInfoDialog title={actions.editSensitive.title} dataType={actions.editSensitive.dataType} data={rowData} forceFetch={forceFetch} />}
                    {actions.editTaxSensitive && <TaxSensitiveInfoDialog title={actions.editTaxSensitive.title} dataType={actions.editTaxSensitive.dataType} data={rowData} forceFetch={forceFetch} />}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setEditReducer: Actions.setEditReducer,
            setInspectReducer: Actions.setInspectReducer,
        },
        dispatch
    );
}

export default withRouter(connect(null, mapDispatchToProps)(DataTableDialog));
