export const LOCATION_ROLES = {
    PROCESSOR: 'processor',
    PRODUCER: 'producer',
    DRIVER: 'driver',
};

export const LOCATION_MILK_TYPES = {
    RAW_MILK: 'RawMilk',
    PROCESSED_FLUID: 'ProcessedFluid',
};

export default LOCATION_ROLES;
