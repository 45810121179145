import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import * as FuseActions from 'app/store/actions';
import { bindActionCreators } from 'redux';
import { DialogContentText } from '@material-ui/core';
import { getFileURL } from '../../repository';

function PaperComponent(props) {
    return (
        // <Draggable>
        <Paper {...props} />
        // </Draggable>
    );
}

class SlipDialog extends React.Component {
    state = {
        slipOpen: false,
        meteredOpen: false,
        slipAddress: '',
        meteredAddress: '',
        fullSlipURL: '',
        fullMeteredURL: '',
    };

    componentDidMount() {
        const { data, type } = this.props;

        switch (type) {
            case 'Pickup':
            case 'Route Session Pickup': {
                this.setState({ slipAddress: data.slip || 'no_slip.png' });
                this.setSlipURLState(data.slip, false);

                break;
            }

            case 'Dropoff':
            case 'Route Session Dropoff': {
                this.setState({ slipAddress: data.slip || 'no_slip.png', meteredAddress: data.metered_slip || 'no_slip.png' });
                this.setSlipURLState(data.slip, false);
                this.setSlipURLState(data.metered_slip, true);

                break;
            }

            case 'DFNSDropoff': {
                this.setState({ meteredAddress: data.metered_slip || 'no_slip.png' });
                this.setSlipURLState(data.metered_slip, true);

                break;
            }
            case 'Wash': {
                this.setState({ slipAddress: data.ticket_s3_location || 'no_slip.png' });
                this.setSlipURLState(data.ticket_s3_location, false);

                break;
            }
            default:
                break;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { slipAddress } = this.state;
        const { shouldAutoOpen } = this.props;

        if (prevState.slipAddress !== slipAddress) {
            if (shouldAutoOpen && slipAddress !== 'no_slip.png' && slipAddress !== '') {
                this.setState({ slipOpen: true });
            }
        }
    }

    handleClickOpen = (type) => {
        this.setState({ slipOpen: type === 'slip', meteredOpen: !(type === 'slip') });
    };

    handleClose = () => {
        this.setState({ slipOpen: false, meteredOpen: false });
    };

    setSlipURLState(slipData, isMeteredSlip) {
        if (slipData && slipData !== 'no_slip.png' && slipData !== '') {
            getFileURL(slipData)
                .then((url) => {
                    if (isMeteredSlip) {
                        this.setState({ fullMeteredURL: url });
                    } else {
                        this.setState({ fullSlipURL: url });
                    }
                })
                .catch((err) => {});
        }
    }

    render() {
        const { type } = this.props;
        const { slipAddress, meteredAddress, slipOpen, meteredOpen, fullSlipURL, fullMeteredURL } = this.state;

        return (
            <div
                style={{
                    margin: 'auto',
                    width: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {!!slipAddress && (
                    <div>
                        <Button variant="contained" color="primary" onClick={() => this.handleClickOpen('slip', { ...this.props, ...this.state })} className="my-16" aria-label={`View ${type} Slip`} disabled={slipAddress === 'no_slip.png' || slipAddress === ''}>
                            {`View ${type} Slip`}
                        </Button>

                        <Dialog open={slipOpen} onClose={this.handleClose} PaperComponent={PaperComponent} fullWidth maxWidth="md" aria-labelledby="draggable-dialog-title" scroll="body">
                            <DialogTitle id="draggable-dialog-title">{`${type} Slip`}</DialogTitle>
                            <DialogContent>
                                {slipAddress.split('.')[1] === 'pdf' && (
                                    <DialogContentText>
                                        <iframe
                                            src={`https://docs.google.com/gview?url=${encodeURIComponent(fullSlipURL)}&embedded=true`}
                                            style={{
                                                minWidth: '100%',
                                                maxWidth: '100%',
                                                minHeight: '70vh',
                                                maxHeight: '70vh',
                                            }}
                                            title="Slip"
                                        />
                                    </DialogContentText>
                                )}
                                {slipAddress.split('.')[1] !== 'pdf' && (
                                    <DialogContentText>
                                        <img style={{ width: '100%', height: '30%' }} src={`${fullSlipURL}`} alt="No slip found" />
                                    </DialogContentText>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleClose} color="primary">
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                )}

                {!!meteredAddress && (
                    <div
                        style={{
                            margin: 'auto',
                            width: 'fit-content',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Button variant="contained" color="primary" onClick={() => this.handleClickOpen('metered', { ...this.props, ...this.state })} className="my-16" aria-label="View Metered Slip" disabled={meteredAddress === 'no_slip.png' || meteredAddress === ''}>
                            {'View Metered Slip'}
                        </Button>

                        <Dialog open={meteredOpen} onClose={this.handleClose} PaperComponent={PaperComponent} fullWidth maxWidth="xs" aria-labelledby="draggable-dialog-title">
                            <DialogTitle id="draggable-dialog-title">Metered Slip</DialogTitle>
                            <DialogContent>
                                {meteredAddress.split('.')[1] === 'pdf' && (
                                    <DialogContentText>
                                        <iframe
                                            src={`https://docs.google.com/gview?url=${encodeURIComponent(fullMeteredURL)}&embedded=true`}
                                            style={{
                                                minWidth: '100%',
                                                maxWidth: '100%',
                                                minHeight: '70vh',
                                                maxHeight: '70vh',
                                            }}
                                            title="Slip"
                                        />
                                    </DialogContentText>
                                )}
                                {meteredAddress.split('.')[1] !== 'pdf' && (
                                    <DialogContentText>
                                        <img style={{ width: '100%', height: '30%' }} src={`${fullMeteredURL}`} alt="No slip found" />
                                    </DialogContentText>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleClose} color="primary">
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                )}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ showMessage: FuseActions.showMessage }, dispatch);
}

export default connect(null, mapDispatchToProps)(SlipDialog);
