import { FuseLoadable } from '@fuse';

const PriceConfiguration = {
    auth: ['admin', 'sub-admin', 'transport', 'sub-transport', 'processor', 'sub-processor'],
    routes: [
        {
            path: '/invoicing/price-configuration/assignments/create-assignment/:month',
            component: FuseLoadable({
                loader: () => {
                    return import('./assignments-table/MutateAssignment');
                },
            }),
        },
        {
            path: '/invoicing/price-configuration/assignments/edit-assignment/:id',
            component: FuseLoadable({
                loader: () => {
                    return import('./assignments-table/MutateAssignment');
                },
            }),
        },
        {
            path: '/invoicing/price-configuration',
            component: FuseLoadable({
                loader: () => {
                    return import('./PriceConfiguration');
                },
            }),
        },
    ],
};
const CheckoutReport = {
    auth: ['admin', 'sub-admin'],
    routes: [
        {
            path: '/invoicing/check-out-report/:date?/:type?',
            exact: true,
            component: FuseLoadable({
                loader: () => {
                    return import('./checkout-report-page/CheckoutReportPlantListPage');
                },
            }),
        },
        {
            path: '/invoicing/check-out-report/:date/:type/:processorId/:processorName?/:dairyId?',
            component: FuseLoadable({
                loader: () => {
                    return import('./checkout-report-page/admin-details-page/CheckoutReportDetailsPage');
                },
            }),
        },
    ],
};

const Utilization = {
    auth: ['admin', 'sub-admin', 'transport', 'sub-transport', 'processor', 'sub-processor'],
    routes: [
        {
            path: '/invoicing/utilization/edit-utilization/:processorId/:month/:dairyId/:processorName',
            component: FuseLoadable({
                loader: () => {
                    return import('./utilization/MutateUtilization');
                },
            }),
        },
        {
            path: '/invoicing/utilization/:date?',
            component: FuseLoadable({
                loader: () => {
                    return import('./utilization/Utilization');
                },
            }),
        },
    ],
};

const ProcessorCheckoutReport = {
    auth: ['processor', 'sub-processor'],
    routes: [
        {
            path: '/invoicing/processor-check-out-report',
            component: FuseLoadable({
                loader: () => {
                    return import('./checkout-report-page/processor-details-page/ProcessorCheckoutReportPage');
                },
            }),
        },
    ],
};

const SummaryPage = {
    auth: ['admin', 'sub-admin'],
    routes: [
        {
            path: '/invoicing/summary/:date?/:type?',
            component: FuseLoadable({
                loader: () => {
                    return import('./summary/InvoicingSummaryPage');
                },
            }),
        },
    ],
};

export default [PriceConfiguration, CheckoutReport, ProcessorCheckoutReport, Utilization, SummaryPage];
