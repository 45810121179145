import axios from 'axios';
import { getEquityMembersQuery } from './queries';
import { buildMembershipQuery } from './queries/builders';

const getEquityUsersList = async (id) => {
    try {
        const membershipQuery = '/v1/equityMembers';
        const queryOptions = { filterDeleted: true, filterSubusers: false, populate: { producers: ['license_number', 'name'] } };

        // mongo query on user collection
        const equityUsersQuery = getEquityMembersQuery(queryOptions);

        // dynamo query on equity-member
        const equityMembershipQuery = buildMembershipQuery(membershipQuery, {
            headers: {},
            data: {},
        });

        const promises = [axios.get(equityUsersQuery), axios.get(equityMembershipQuery.url)];
        const promiseResponse = await Promise.all(promises);

        const equityUsers = promiseResponse[0].data;
        let equityMembershipResults = promiseResponse[1].data;
        let equityMembershipData = promiseResponse[1].data?.results;

        while (equityMembershipResults?.lastEvaluatedKey) {
            const { lastEvaluatedKey } = equityMembershipResults;
            const nextQuery = buildMembershipQuery(
                membershipQuery,
                {
                    headers: {},
                    data: {},
                },
                lastEvaluatedKey
            );
            // eslint-disable-next-line no-await-in-loop
            const nextResponse = await axios.get(nextQuery.url);
            equityMembershipResults = nextResponse.data;
            equityMembershipData = [...equityMembershipData, ...nextResponse.data.results];
        }
        if (!Array.isArray(equityMembershipData)) {
            equityMembershipData = [equityMembershipData];
        }

        const mergedData = [];
        equityUsers.forEach((producerBusiness) => {
            const producerBusinessId = producerBusiness.id ?? producerBusiness._id;
            const equityMembership = equityMembershipData.find((equityMember) => equityMember?.id === producerBusinessId);
            delete equityMembership?.id;
            const obj = { ...equityMembership, ...producerBusiness };
            mergedData.push(obj);
        });

        return mergedData.map(({ _id, name, equity_id, ...rest }) => ({
            id: _id,
            full_name: name,
            equity_account_id: equity_id,
            ...rest,
        }));
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        throw new Error('Unable to process request.');
    }
};

export default getEquityUsersList;
